import React from "react";
import { Link } from "react-router-dom";

class RepairBoxes extends React.Component {
  render() {
    return (
      <section className="repair-boxes-area">
        <div className="container">
          <div className="row m-0">
            <div className="col-lg-6 col-md-6 offset-lg-0 offset-md-4 p-0">
              <div className="single-repair-box" style={{ padding: "25px" }}>
                {/* <i className="icofont-smart-phone"></i> */}
                <h3 style={{ fontSize: "34px" }}>
                  GENERAL COUNSELS – <br />
                  LEGAL DEPARTMENTS
                </h3>
                <p>
                  Instantly access your legal service providers (law firms,
                  ALSPs, etc.) work and activity LIVE in a dashboard view for
                  accurate financial reporting and better decisions on your
                  matters. Use LeGuard’s internal legal department feature to
                  see your entire team’s work as it occurs.
                </p>
                <Link to="/general">Learn More</Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 offset-lg-0 offset-md-4">
              <div className="single-repair-box">
                {/* <i className="icofont-imac"></i> */}
                <h3 style={{ fontSize: "34px" }}>LEGAL SERVICE PROVIDERS</h3>
                <p>
                  LeGuard’s constant communication helps legal service providers
                  win new business, better manage their virtual workforce and
                  keep their clients up-to-date on work and accruing fees while
                  reducing administrative burdens.
                </p>
                <Link to="/lawfirms">Learn More</Link>
                {/* 
                                <div className="img-box">
                                    <img src={require('../../assets/img/shape1.png')} alt="box" />
                                    <img src={require('../../assets/img/shape2.png')} alt="box" />
                                </div> */}
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6 p-0">
                            <div className="single-repair-box active">
                                <h3 style={{ fontSize: '34px' }}>GENERAL COUNSELS – LEGAL DEPARTMENTS</h3>
                                <p>LeGuard’s patented design enables legal departments to instantly access accurate information for financial reporting, bill reviews, budget forecasts, and overall activity/spend status — down to the individual matter level.</p>
                                <Link to="/general">Learn More</Link>
                            </div>
                        </div> */}
          </div>
        </div>
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <h3 style={{ color: "darkred" }}>
            Tracking Legal Fees in Real Time®
            <span style={{ fontSize: "50%", fontStyle: "italic" }}>
              <br />
              For
            </span>{" "}
            <br />
            Legal Spend Integrity®
          </h3>
        </div>
      </section>
    );
  }
}

export default RepairBoxes;
