import React from 'react';
import { Link } from 'react-router-dom';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import NavBarMultiPage from "../components/common/NavBarMultiPage";
import Footer from "../components/versionSeven/Footer";

class Faq extends React.Component {
    render() {
        return (
            <React.Fragment>
                <NavBarMultiPage pageName="faq" />
                <div className="page-title">
                    <div className="pattern-2" />
                    <div className="bg-top" />
                    <div className="bg-bottom" />
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <div className="page-title-content">
                                    <h3>Frequently asked questions<br/> about LeGuard</h3>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="faq-area pb-0">
                    <div className="container">
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What is LeGuard?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        LeGuard is a software application that technologically connects the client with its outside counsel and other billers thus enabling the client to track legal activities and spend as they accrue. LeGuard provides a user friendly dashboard that tracks all legal activity and spend by firm, by matter, and by biller. The responsible partner, too, has a dashboard that mirrors the client’s activity dashboard.
    LeGuard’s tracking capabilities improves communication, transparency and efficiency — resulting in stronger relationships between corporate and outside counsels and reduced legal spend.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        I already have an e-billing system. What makes LeGuard unique?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        LeGuard is the only software that allows both clients and lawyers to view and capture legal spend and activity in the moment, as it occurs. E-billing systems purport to offer “real-time visibility,” but this is somewhat misleading. With today’s culture of legal billing, invoices or a “pre-bill” in an e-billing system contain a description of legal activity and the number of hours billed to such activity. All information contained in the system is a billers’ retrospective view of legal activity and the hours billed to such activity. If a biller has not entered his/her time, the information does not exist in the e-billing system. LeGuard uniquely requires billers to record their time as legal activity is occurring. There is no guesswork associated with entering time because the time has already been captured. The client has the data and can compare final invoices to the information contained in the LeGuard system.
    
    LeGuard was not intended to replace e-billing, but rather to compliment e-billing systems so the client has the confidence that bills have been verified and validated by LeGuard live tracking system. However, for those companies that do not have e-billing, LeGuard can serve to validate paper invoices.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        So LeGuard is unique—but how will that help me save money?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        First, LeGuard will help you save time (and therefore money) with gathering information informally for accrual, budgeting and forecasting. Second, LeGuard will help you save time (and therefore money) with invoice review, by providing objective data on billable activity, captured live in the moment, so that your in-house counsel have something other than their “gut feel” to review the reasonableness of invoices.
    
    The LeGuard system promotes greater accountability, transparency and accuracy from   billers through LIVE entering of their data into LeGuard.  This allows both in-house lawyers and their outside counsel to be alerted to and impact the activity of   billers as it is occurring.  Clients using LeGuard have saved between 10% and 22% on legal spend in studies comparing the same law firms and lawyers, for very similar matters, in the same industry and often with the same plaintiff’s counsel.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        How exactly does LeGuard track legal fees LIVE?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        It’s simple. When an   biller commences a billable activity (i.e. legal research, drafting, deposition, trial, etc.), he or she selects the client, matter and activity in LeGuard —and then clicks “Start.” LeGuard will track the time spent (and spend accrued) on that activity until the   biller clicks “Stop.” Responsible lawyers and clients are notified that the activity has started when they hit budgets (set by matter or by phase of the matter) and can also see the activities, spend and the budget consumed at any time.
                                    </p>
                                    <br />
                                    <p>It’s that easy!</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        How long does it take to start using LeGuard?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Unlike other systems that go into the client’s and/or law firm’s infrastructure, LeGuard is a secure, web-based application that allows you to select individual matters and enroll only those lawyers (not the whole firm) and other   billers engaged in them. This means there’s no need for integration across your or your law firm’s entire IT platform. LeGuard is highly intuitive and takes less than two minutes to register an   biller, then he or she can begin using it. For clients, it takes even less time!
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        How do I register for LeGuard?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Contact us at <a href="mailto:sales@leguard.com">sales@leguard.com</a>
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        How do law firms use LeGuard?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        LeGuard is your billers’ digital note taking system for all their billable activity tracking. First and foremost, LeGuard does not impact the current process or systems of how clients and law firms interact and invoice. They stay exactly the same, except that the client asks the   billers to notify the responsible lawyer and client when they begin work – by using LeGuard- to provide all of the benefits discussed above. That task takes seconds. After the client registers with LeGuard, the lawyers and other   billers working for the client will be asked to register and connect with LeGuard. Once registered, you’re ready to go.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    How do I access LeGuard on my computer or mobile device?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        To access LeGuard from a computer, the user simply logs onto www.LeGuard.com and clicks the “Start Using LeGuard” link. For mobile devices, free apps are available for Apple and Android devices at the Apple store and Google Play, respectively.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What happens if an biller forgets to click the stop button for a billable activity?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        An activity will automatically stop after 24 hours of continuous operation. Billers can revise this activity to correct the stop time.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            {/* <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        When an   biller switches activities for a particular client matter (i.e., the user stops performing legal research and starts preparing for a deposition), does the   biller need to press the Stop button to reset the tracking of time?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        This is up to your clients and their billing preferences, however, many do not like block billing for activities (for a variety of reasons). So, in order to accurately record specific activities, when the   biller finishes one client-billable activity and switches to a different activity, he or she should click Stop, then choose a different activity, and click Start again. LeGuard will commence tracking the amount of time spent on the new activity. LeGuard facilitates line item activity tracking and limits block billing.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem> */}
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Can an   biller manually modify, change or correct a LeGuard tracking entry?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Yes. Using the revise feature, an authorized biller may modify and delete existing activities and add new activities. However, there are some time limitations for creating or increasing the time on an activity (set by your client). . Also, an activity cannot be created or modified so that its time range overlaps with an existing activity.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Does LeGuard need an Internet connection to function? What if I’m working on an airplane?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    No. The information of the   biller will be kept in his/her device (computer or mobile) until an Internet connection is established. At that time, the   biller’s activities will be automatically uploaded to the LeGuard system. For clients and law firm partners monitoring the activities of   billers, an Internet connection is required to see live updates.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    What activities can LeGuard track?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    LeGuard provides clients the ability to track whatever they choose, including UTBMS and custom coding.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    What kind of reports can LeGuard generate?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    LeGuard has a large list of standard reports created by the system including information by activity, lawyer, matter, law firm, time period (daily, monthly, quarterly, yearly, etc.), total and others. These reports can be exported in any number of ways including .csv, .pdf, .xls and others. Plus, through our APIs, our clients can bring LeGuard’s data into any of their systems, and generate whatever reports they wish to from that data.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        );
    }
}

export default Faq;