import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Container, NavDropdown, Nav, Dropdown } from "react-bootstrap";
import logo from '../../assets/img/logo.png';

class NavBarMultiPage extends Component {
    componentDidMount() {
        // let elem = document.getElementById("navbar");
        // document.addEventListener("scroll", () => {
        //     if (window.scrollY > 170) {
        //         elem.classList.add("is-sticky");
        //     } else {
        //         elem.classList.remove("is-sticky");
        //     }
        // });
        // let scrollWithOffset = (el, offset) => {
        //     const elementPosition = el.offsetTop - offset;
        //     window.scroll({
        //         top: elementPosition,
        //         left: 0,
        //         behavior: "smooth"
        //     });
        // };
        // this.setState({ scrollWithOffset });
    }

    closeNavbar() {
        if (window.matchMedia("screen and (max-width: 991px)").matches) {
            document.getElementById("collaspe-btn").click();
        }
    }

    render() {
        return (
            <React.Fragment>
                <Navbar
                    sticky="top"
                    id="navbar"
                    bg="light"
                    expand="lg"
                    className="navbar navbar-expand-lg navbar-light bg-light"
                    collapseOnSelect={true}
                >
                    <Container>
                        <LinkContainer exact to="/">
                            <Navbar.Brand className="navbar-brand">
                                <img src={logo} style={{ maxHeight: '70px' }}></img>
                            </Navbar.Brand>
                        </LinkContainer>

                        <Navbar.Toggle
                            aria-controls="basic-navbar-nav"
                            id="collaspe-btn"
                        />

                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ml-auto">
                                {/* <Dropdown>
                                    <NavDropdown title="Home" id="nav-dropdown">
                                        <LinkContainer exact to="/">
                                            <NavDropdown.Item>
                                                Home Version One
                                            </NavDropdown.Item>
                                        </LinkContainer>
                                        <LinkContainer to="/home-two">
                                            <NavDropdown.Item>
                                                Home Version Two
                                            </NavDropdown.Item>
                                        </LinkContainer>
                                        <LinkContainer to="/home-three">
                                            <NavDropdown.Item>
                                                Home Version Three
                                            </NavDropdown.Item>
                                        </LinkContainer>
                                        <LinkContainer to="/home-four">
                                            <NavDropdown.Item>
                                                Home Version Four
                                            </NavDropdown.Item>
                                        </LinkContainer>
                                        <LinkContainer to="/home-five">
                                            <NavDropdown.Item>
                                                Home Version Five
                                            </NavDropdown.Item>
                                        </LinkContainer>
                                        <LinkContainer to="/home-six">
                                            <NavDropdown.Item>
                                                Home Version Six
                                            </NavDropdown.Item>
                                        </LinkContainer>
                                        <LinkContainer 
                                            to="/home-seven"
                                            onClick={() => window.location.refresh()}
                                        >
                                            <NavDropdown.Item>
                                                Home Version Seven
                                            </NavDropdown.Item>
                                        </LinkContainer>
                                        <LinkContainer to="/one-page-template">
                                            <NavDropdown.Item>
                                                One Page Template
                                            </NavDropdown.Item>
                                        </LinkContainer>
                                    </NavDropdown>
                                    <Dropdown.Menu className="super-colors">
                                        
                                    </Dropdown.Menu>
                                </Dropdown>

                                <Dropdown>
                                    <NavDropdown title="Pages" id="nav-dropdown">
                                        <LinkContainer to="/about">
                                            <NavDropdown.Item>About</NavDropdown.Item>
                                        </LinkContainer>
                                        <LinkContainer to="/services">
                                            <NavDropdown.Item>Services</NavDropdown.Item>
                                        </LinkContainer>
                                        <LinkContainer to="/team">
                                            <NavDropdown.Item>Team</NavDropdown.Item>
                                        </LinkContainer>
                                        <LinkContainer to="/pricing">
                                            <NavDropdown.Item>Pricing</NavDropdown.Item>
                                        </LinkContainer>
                                    </NavDropdown>
                                </Dropdown> */}

                                <LinkContainer exact to="/">
                                    <Nav.Link >Home</Nav.Link>
                                </LinkContainer>

                                <LinkContainer to="/about">
                                    <Nav.Link>Why LeGuard?</Nav.Link>
                                </LinkContainer>

                                <Dropdown>
                                    <NavDropdown title="Values / Features" id="nav-dropdown">
                                        <LinkContainer to="/general">
                                            <NavDropdown.Item>General Counsels - Legal Departments</NavDropdown.Item>
                                        </LinkContainer>
                                        {/* <LinkContainer to="/attorneys">
                                            <NavDropdown.Item>Billers</NavDropdown.Item>
                                        </LinkContainer> */}
                                        <LinkContainer to="/lawfirms">
                                            <NavDropdown.Item>Legal Service Providers</NavDropdown.Item>
                                        </LinkContainer>
                                    </NavDropdown>
                                </Dropdown>
{/* 
                                <LinkContainer to="/faq">
                                    <Nav.Link>FAQs</Nav.Link>
                                </LinkContainer> */}
{/* 
                                <LinkContainer to="/team">
                                    <Nav.Link>Meet Our Team</Nav.Link>
                                </LinkContainer> */}

                                <LinkContainer to="/contact">
                                    <Nav.Link>Contact</Nav.Link>
                                </LinkContainer>

                                <a href={process.env.REACT_APP_MAIN_WEBSITE} className="nav-link">LogIn</a>

                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </React.Fragment>
        );
    }
}

export default NavBarMultiPage;
