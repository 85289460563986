import React from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel3';
import aboutImg2 from '../../assets/img/img2leguard.png';
const options = {
    loop: true,
    autoplay: true,
    nav: false,
    mouseDrag: true,
    autoplayHoverPause: true,
    responsiveClass: true,
    dots: true,
    navText: [
        "<i class='icofont-double-left'></i>",
        "<i class='icofont-double-right'></i>"
    ],
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        1200: {
            items: 3,
        }
    }
}

class Services extends React.Component {
    render() {
        return (
            <section id="about2" className="repair-about-area ptb-100 leguard-full-width-div">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <img src={aboutImg2} style={{maxHeight:'300px'}}></img>
                        </div>
                        <div className="col-lg-6">
                            <div className="repair-about-content">
                                <div className="section-title">
                                    <h3>Easy to Use - Easy to Access</h3>
                                    <p>LeGuard’s user friendly software can be activated in less than 5 minutes and is accessible via computer or mobile device. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            // <section id="services" className="repair-services-area ptb-100 pb-0">
            //     <div className="container">
            //         <div className="section-title text-center">
            //             <span>Our Services</span>
            //             <h3>What we offer for you</h3>
            //             <p>We enjoy adapting our strategies to offer every client the best solutions that are at the forefront of the industry.</p>
            //         </div>

            //         <div className="row">
            //             <OwlCarousel 
            //                 className="repair-services-slides owl-carousel owl-theme"
            //                 {...options}
            //             >
            //                 <div className="col-lg-12 col-md-12">
            //                     <div className="single-repair-services">
            //                         <i className="icofont-laptop-alt"></i>
            //                         <h3>Laptop Repair</h3>
            //                         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

            //                         <Link to="#" className="read-more-btn">Read More</Link>
            //                     </div>
            //                 </div>

            //                 <div className="col-lg-12 col-md-12">
            //                     <div className="single-repair-services">
            //                         <i className="icofont-computer"></i>
            //                         <h3>Computer Repair</h3>
            //                         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

            //                         <Link to="#" className="read-more-btn">Read More</Link>
            //                     </div>
            //                 </div>

            //                 <div className="col-lg-12 col-md-12">
            //                     <div className="single-repair-services">
            //                         <i className="icofont-responsive"></i>
            //                         <h3>Apple Products Repair</h3>
            //                         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

            //                         <Link to="#" className="read-more-btn">Read More</Link>
            //                     </div>
            //                 </div>

            //                 <div className="col-lg-12 col-md-12">
            //                     <div className="single-repair-services">
            //                         <i className="icofont-ui-touch-phone"></i>
            //                         <h3>Mobile Repair</h3>
            //                         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

            //                         <Link to="#" className="read-more-btn">Read More</Link>
            //                     </div>
            //                 </div>
            //             </OwlCarousel>
            //         </div>

            //         <div className="repair-services-inner">
            //             <div className="row">
            //                 <div className="col-lg-4">
            //                     <div className="single-inner-services">
            //                         <i className="icofont-handshake-deal"></i>
            //                         <h3>Quick Repair Process</h3>
            //                         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
            //                     </div>
            //                 </div>

            //                 <div className="col-lg-4">
            //                     <div className="single-inner-services">
            //                         <i className="icofont-wrench"></i>
            //                         <h3>Free Diagnostics</h3>
            //                         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
            //                     </div>
            //                 </div>

            //                 <div className="col-lg-4">
            //                     <div className="single-inner-services">
            //                         <i className="icofont-fast-delivery"></i>
            //                         <h3>Fast Delivery</h3>
            //                         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </section>
        );
    }
}

export default Services;