import React, { Component } from "react";
import axios from "axios";
import Subscribe from "../components/common/Subscribe";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import NavBarMultiPage from "../components/common/NavBarMultiPage";
import GoogleMap from "../components/common/GoogleMap";
// import Footer from "../components/common/Footer";
import Footer from "../components/versionSeven/Footer";
import api from "../_services/api.service";

class ContactUS extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            subject: "",
            message: "",
            successMsg: ""
        };
    }

    handleForm = e => {
        let that = this;
        api.submitMessage(this.state.email, this.state.message, this.state.name, this.state.subject).then(data => {
            document.getElementById("contactForm").reset();
            that.setState({
                successMsg: "We received your submission"
            });
        })
     
    };

    handleFields = e => this.setState({ [e.target.name]: e.target.value });

    render() {
        return (
            <React.Fragment>
                {/* NavBarMultiPage: src/components/common/NavBarMultiPage */}
                <NavBarMultiPage pageName="contact" />
                <div>
                    {/* Start Page Title Area */}
                    <div className="page-title">
                        <div className="pattern-2" />
                        <div className="bg-top" />
                        <div className="bg-bottom" />
                        <div className="d-table">
                            <div className="d-table-cell">
                                <div className="container">
                                    <div className="page-title-content" style={{marginTop: '-100px'}}>
                                        <h3>Legal Spend Integrity® Software: <br />Sales and Support <br/>Contact</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Page Title Area */}
                    {/* Start Contact Area */}
                    <section className="contact-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-12">
                                    <div className="contact-box">
                                        <div className="icon">
                                            <i className="icofont-envelope" />
                                        </div>
                                        <div className="content">
                                            <h4>{this.props.emailTitle}</h4>
                                            <p>
                                                <a
                                                    href={"mailto:info@leguard.com"}
                                                >
                                                    info@leguard.com
                                                </a>
                                            </p>
                                            <p>
                                                <a
                                                    href={"mailto:sales@leguard.com"}
                                                >
                                                    sales@leguard.com
                                                </a>
                                            </p>
                                            <p>
                                                <a
                                                    href={"mailto:training@leguard.com"}
                                                >
                                                    training@leguard.com
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="contact-box">
                                        <div className="icon">
                                            <i className="icofont-google-map" />
                                        </div>
                                        <div className="content">
                                            <p>
                                                1751 Pinnacle Drive<br />
                                                Suite 1100<br />
                                                McLean, Virginia 22102
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-lg-4 col-md-12">
                                    <div className="contact-box">
                                        <div className="icon">
                                            <i className="icofont-envelope" />
                                        </div>
                                        <div className="content">
                                            <h4>{this.props.emailTitle}</h4>
                                            <p>
                                                <a
                                                    href={
                                                        "mailto:" +
                                                        this.props
                                                            .emailAddressOne +
                                                        ""
                                                    }
                                                >
                                                    {this.props.emailAddressOne}
                                                </a>
                                            </p>
                                            <p>
                                                <a
                                                    href={
                                                        "mailto:" +
                                                        this.props
                                                            .emailAddressTwo +
                                                        ""
                                                    }
                                                >
                                                    {this.props.emailAddressTwo}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <div className="contact-box">
                                        <div className="icon">
                                            <i className="icofont-google-map" />
                                        </div>
                                        <div className="content">
                                            <h4>{this.props.locationTitle}</h4>
                                            <p>
                                                {
                                                    this.props
                                                        .locationAddressLineOne
                                                }
                                                <span>
                                                    {
                                                        this.props
                                                            .locationAddressLineTwo
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-lg-8 col-md-12">
                                    <div id="map">
                                        {/* GoogleMap: src/components/common/GoogleMap */}
                                        <GoogleMap />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <div className="contact-text">
                                        <h2 style={{ color: 'darkred' }}>{this.props.contactTitle}</h2>
                                        <p style={{fontSize:'18px'}}>
                                            For <b>questions</b> or to <b>request a demo</b> of how LeGuard can help your organization, please fill out the form on the right and a representative will contact you shortly.
                                        </p>

                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-12">
                                    <ValidationForm
                                        id="contactForm"
                                        onSubmit={(e, formData, inputs) => {
                                            e.preventDefault();
                                            this.handleForm(formData);
                                        }}
                                    >
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12">
                                                <div className="form-group">
                                                    <TextInput
                                                        name="name"
                                                        id="name"
                                                        className="form-control"
                                                        placeholder="Name"
                                                        autoComplete="off"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                    <div className="help-block with-errors" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="form-group">
                                                    <TextInput
                                                        name="email"
                                                        id="email"
                                                        type="email"
                                                        required
                                                        successMessage=""
                                                        errorMessage="Please enter your email address"
                                                        className="form-control"
                                                        placeholder="Email"
                                                        autoComplete="off"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                    <div className="help-block with-errors" />
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <TextInput
                                                        name="subject"
                                                        id="subject"
                                                        type="text"
                                                        //required
                                                        successMessage=""
                                                        errorMessage="Please enter your email subject"
                                                        className="form-control"
                                                        placeholder="Subject"
                                                        autoComplete="off"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <TextInput
                                                        name="message"
                                                        id="description"
                                                        multiline
                                                        placeholder="Your message"
                                                        className="form-control"
                                                        required
                                                        successMessage=""
                                                        errorMessage="Please write your message"
                                                        rows="5"
                                                        autoComplete="off"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <button className="btn btn-primary">
                                                Submit
                                            </button>
                                        </div>
                                        <div className="clearfix" />
                                    </ValidationForm>
                                    {this.state.successMsg !== "" ? (
                                        <h3 className="contactMsg">
                                            {this.state.successMsg}
                                        </h3>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Start Contact Area */}
                </div>
                {/* Subscribe: src/components/common/Subscribe */}
                {/* <Subscribe /> */}
                <Footer />
            </React.Fragment>
        );
    }
}

//Props Types
ContactUS.propTypes = {
    sectionName: PropTypes.string,

    phoneTitle: PropTypes.string,
    phoneNoOne: PropTypes.string,
    phoneNoTwo: PropTypes.string,
    emailTitle: PropTypes.string,
    emailAddressOne: PropTypes.string,
    emailAddressTwo: PropTypes.string,
    locationTitle: PropTypes.string,
    locationAddressLineOne: PropTypes.string,
    locationAddressLineTwo: PropTypes.string,

    contactTitle: PropTypes.string,
    contactDescriptionsOne: PropTypes.string,
    contactDescriptionsTwo: PropTypes.string
};
//Default Props
ContactUS.defaultProps = {
    sectionName: "CONTACT AXOLOT",

    phoneTitle: "Phone Number",
    phoneNoOne: "(+021) 245522455",
    phoneNoTwo: "(+021) 245522456",
    emailTitle: "E-mail",
    emailAddressOne: "info@Axolot.com",
    emailAddressTwo: "support@Axolot.com",
    locationTitle: "Location",
    locationAddressLineOne: "2750 Quadra Street , Park Area,",
    locationAddressLineTwo: "Victoria, Canada.",

    contactTitle: "Get in Touch..",
    contactDescriptionsOne:
        "Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Etiam tempus magna vel turpis pharetra dictum.",
    contactDescriptionsTwo:
        "Sed blandit tempus purus, sed sodales leo rutrum vel. Nam vulputate ipsum ac est congue, eget commodo magna lobortis."
};

export default ContactUS;
