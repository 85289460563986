import React, { Component } from 'react';

import NavBarMultiPage from "../common/NavBarMultiPage";//"../components/common/NavBarMultiPage";
import Footer from "../versionSeven/Footer";
import start from '../../assets/img/monitorstart.png';
import stop from '../../assets/img/monitorstop.png';
import i1 from '../../assets/img/i1.png';
import i2 from '../../assets/img/i2.png';
import i3 from '../../assets/img/i3.png';
import clock from '../../assets/img/clock.png';

class Attorneys extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <React.Fragment>
                <NavBarMultiPage pageName="contact" />
                <div className="page-title">
                    <div className="pattern-2" />
                    <div className="bg-top" />
                    <div className="bg-bottom" />
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <div className="page-title-content">
                                    <h3>Billers</h3>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section id="general" className="about-area">
                    <div className="section-title">
                        <h3 style={{ maxWidth: '800px' }}>
                        LeGuard helps billers increase their productivity by easily and accurately recording their time and activities as they work

                    </h3>

                    </div>
                    <div className="repair-about-area ptb-100 leguard-full-width-div">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="repair-about-content">
                                        <div className="section-title">
                                            <h3>Problems</h3>
                                            <ul>
                                                <li><i class="icofont-clock-time"></i>Time-consuming data entry process</li>
                                                <li><i class="icofont-close-circled"></i>Difficulty recalling time/activities accurately</li>
                                                <li><i class="icofont-close-circled"></i>Disruptive ad hoc and pre-billing accrual information requests</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4" style={{ textAlign: 'center' }}>
                                    <span className="helper"></span>
                                    <img src={clock} style={{ width: '50%', verticalAlign: 'middle' }}></img>
                                </div>
                                <div className="col-lg-4">
                                    <div className="repair-about-content">
                                        <div className="section-title">
                                            <h3>Solved!</h3>
                                            <ul>
                                                <li><i class="icofont-check-circled"></i>Simple, quick and convenient time/activities entry – all in one place</li>
                                                <li><i class="icofont-check-circled"></i>More time/better time management</li>
                                                <li><i class="icofont-check-circled"></i>Always up-to-date time/activities (no more fire drills)</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row mt-100">
                            <div className="col-lg-6 col-md-6">
                                <div className="img">
                                    <img src={start} alt="img" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="about-text mb-0">
                                    <h3 style={{ color: '#941212' }}>LeGuard Eliminates Time-Consuming and Retroactive Time/Activity Tracking</h3>

                                    <ul>
                                        <li><i class="icofont-dollar-true"></i> No more filling out onerous time sheets;</li>
                                        <li><i className="icofont-attachment"></i> No more reconstructing activities from days or weeks before;</li>
                                        <li><i class="icofont-chart-histogram-alt"></i> No more scrambling to respond to client accrual, budget, WIP and invoicing inquiries.</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className="row mt-100">

                            <div className="col-lg-6 col-md-6">
                                <div className="about-text mb-0">
                                    <h3 style={{ color: '#941212' }}>LeGuard Keeps Responsible Lawyers Aware of Their Teams’ Workloads and Budgets</h3>
                                    <p>At any time, Responsible Lawyers can assess how much time their teams are spending on cases, whether they are operating efficiently, and how much more support or resources they may need. Plus, they can better manage and impact activity as it is occurring rather than after the fact.</p>
                                </div>
                                <div className="about-text mb-0">
                                    <h3 style={{ color: '#941212' }}>LeGuard Improves Communication with Clients</h3>
                                    <p>Like any relationship, communication is the key to its strength.  LeGuard, through its Dashboard and alerts, is constantly communicating objective and actionable data that is important to both clients and outside counsel.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="img">
                                    <img src={stop} alt="img" />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-100">
                            <div className="col-lg-12">
                                <h4 style={{ textAlign: 'center' }}>With LeGuard, entering time and activities is as easy as 1-2-3:</h4>
                                <br />
                                <br />
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <img src={i1}></img><br />
                                <h4 className="attorneys-desc">1. Designate the client, matter and task on a computer, tablet or phone — any time, anywhere.</h4>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <img src={i2}></img>
                                <h4 className="attorneys-desc">2. Click the “Start” button to begin tracking</h4>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <img src={i3}></img>
                                <h4 className="attorneys-desc">3. Click the “Stop” button to end tracking</h4>
                            </div>
                        </div>

                    </div>
                </section>
                <Footer />
            </React.Fragment>
        );
    }
}

export default Attorneys;