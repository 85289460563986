import React from 'react';
import { Link } from 'react-router-dom';
 
class Blog extends React.Component {
    render(){
        return (
            <section id="blog" className="repair-blog-area ptb-100">
                <div className="container">
                    <div className="section-title">
                        <span>Our blog</span>
                        <h3>Recent Axolot news</h3>
                        <p>We enjoy adapting our strategies to offer every client the best solutions that are at the forefront of the industry.</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-repair-blog-post">
                                <div className="blog-image">
                                    <Link to="#"><img src={require("../../assets/img/blog-one.jpg")} alt="blog" /></Link>

                                    <div className="tags"><Link to="#">Technology</Link></div>
                                </div>

                                <div className="blog-content">
                                    <h3><Link to="#">Where and How to watch live stream today</Link></h3>

                                    <ul>
                                        <li>July 10, 2019</li>
                                        <li>by: <Link to="#">admin</Link></li>
                                        <li><Link to="#" className="read-more-btn">Read More</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="single-repair-blog-post">
                                <div className="blog-image">
                                    <Link to="#"><img src={require("../../assets/img/blog-two.jpg")} alt="blog" /></Link>

                                    <div className="tags"><Link to="#">Technology</Link></div>
                                </div>

                                <div className="blog-content">
                                    <h3><Link to="#">TikTok illegally collecting data, sharing with China</Link></h3>

                                    <ul>
                                        <li>July 10, 2019</li>
                                        <li>by: <Link to="#">admin</Link></li>
                                        <li><Link to="#" className="read-more-btn">Read More</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                            <div className="single-repair-blog-post">
                                <div className="blog-image">
                                    <Link to="#"><img src={require("../../assets/img/blog-three.jpg")} alt="blog" /></Link>

                                    <div className="tags"><Link to="#">Technology</Link></div>
                                </div>

                                <div className="blog-content">
                                    <h3><Link to="#">The five devices you need to work anytime and anywhere</Link></h3>

                                    <ul>
                                        <li>July 10, 2019</li>
                                        <li>by: <Link to="#">admin</Link></li>
                                        <li><Link to="#" className="read-more-btn">Read More</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Blog;