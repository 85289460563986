import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import NavBarMultiPage from "../components/common/NavBarMultiPage";
import Footer from "../components/versionSeven/Footer";
import image1 from "../assets/img/img3leguard.png";
import image2 from "../assets/img/devices.png";

class About extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <React.Fragment>
        <NavBarMultiPage pageName="contact" />
        <div className="page-title">
          <div className="pattern-2" />
          <div className="bg-top" />
          <div className="bg-bottom" />
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="page-title-content">
                  <h3>Why LeGuard?</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="about" className="about-area">
          <div className="container">
            <div className="section-title">
              <h3>
                <b>LIVE</b> Visibility of Work and Spend – Legal Service
                Providers and Inhouse
              </h3>
              <p>
                Combining accuracy with simplicity, efficiency and transparency,
                LeGuard is transforming how clients and their legal service
                providers communicate and manage their professional and
                financial resources.
              </p>
            </div>
            <div className="row mt-100">
              <div className="col-lg-6 col-md-6">
                <div className="img">
                  <img src={image1} alt="img" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="about-text mb-0">
                  <p>
                    In business today, having as much information, as early as
                    possible, in an easy to digest form makes for better
                    business decisions and allows you to manage your business
                    more effectively.
                  </p>
                  <br />
                  <p>
                    The practice of law is no different - whether as a legal
                    service provider, inhouse or collaborating on matters
                    between the two, having visibility over legal work and its
                    corresponding cost is extremely important – now more than
                    ever.
                  </p>
                  <br />
                  <p>
                    As in any relationship, transparency and communication is
                    key – both for those performing the work so they are
                    coordinated and informed, and to those to whom they are
                    providing the work. Financial surprises, being over-budget
                    or errors in reporting can impact the legal relationship and
                    each sides business.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-100">
              <div className="col-lg-6 col-md-6">
                <div className="about-text mb-0">
                  <h3 style={{ color: "#941212" }}>
                    LeGuard is the only technology that eliminates surprises in
                    billing, reporting, budgeting and forecasting of legal work
                  </h3>
                  <p>
                    With access to LeGuard’s dashboard, both inhouse and legal
                    service providers have instant visibility into work that is
                    being performed and its accruing costs so they can manage
                    their business and provide their clients or internal finance
                    team with accurate financial information. Time, money and
                    resources are not wasted chasing numbers or estimating costs
                    – LeGuard provides this instantly for all.
                  </p>
                  <br />
                  {/* <p>
                    The workflow of the biller is unaffected by LeGuard; they
                    simply communicate with the client through a click of a
                    button as they perform their work.
                  </p> */}
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="img">
                  <img src={image2} alt="img" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

//Props Types
About.propTypes = {
  sectionName: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionDescription: PropTypes.string,
  aboutImage: PropTypes.string,
  aboutTitle: PropTypes.string,
  aboutDescription: PropTypes.string,
  aboutListItemOne: PropTypes.string,
  aboutListItemTwo: PropTypes.string,
  aboutListItemThree: PropTypes.string,
  aboutListItemFour: PropTypes.string,
  aboutListItemFive: PropTypes.string,
  aboutListItemSix: PropTypes.string,
  aboutListItemSeven: PropTypes.string,
  aboutListItemEight: PropTypes.string,
  aboutBtnText: PropTypes.string,
  aboutBtnLink: PropTypes.string,
  aboutsData: PropTypes.array,
};

//Default Props
About.defaultProps = {
  aboutImage: require("../assets/img/about.png"),
  aboutTitle: "We are branding strategy service from 2001 for our customers.",
  aboutDescription:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  aboutListItemOne: "Creative Design",
  aboutListItemTwo: "Retina Ready",
  aboutListItemThree: "Responsive Design",
  aboutListItemFour: "Creative Design",
  aboutListItemFive: "Modern Design",
  aboutListItemSix: "Awesome Design",
  aboutListItemSeven: "Digital Marketing & Branding",
  aboutListItemEight: "Creative Design",
  aboutBtnText: "Read More",
  aboutBtnLink: "#0",
  aboutsData: [
    {
      image: require("../assets/img/1.png"),
      position: ".01",
      title: "Get Started with our software",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.",
      listItemOne: "Creative Design",
      listItemTwo: "Retina Ready",
      listItemThree: "Modern Design",
      listItemFour: "Digital Marketing & Branding",
    },
    {
      image: require("../assets/img/2.png"),
      position: ".02",
      title: "Solve your problem faster",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.",
      listItemOne: "Creative Design",
      listItemTwo: "Retina Ready",
      listItemThree: "Modern Design",
      listItemFour: "Digital Marketing & Branding",
    },
    {
      image: require("../assets/img/3.png"),
      position: ".03",
      title: "All In one for all products",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.",
      blockQuote:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    },
    {
      image: require("../assets/img/4.png"),
      position: ".04",
      title: "We provide proffesional staff",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.",
      blockQuote:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    },
  ],
};

export default About;
