import React from "react";
import { Link } from "react-router-dom";
import playstore from "../../assets/img/playstore.png";
import applestore from "../../assets/img/applestore.png";
import api from "../../_services/api.service";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import { Modal, Button } from "react-bootstrap";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      email: "",
      message: "",
      successMsg: false,
      showTerms: false,
      showPolicy: false,
    };
  }
  handleForm = (e) => {
    api
      .submitMessage(this.state.email, this.state.message, "", "")
      .then((data) => {
        document.getElementById("cform").reset();
        this.setState({ successMsg: true });
      });
  };
  handleFields = (e) => this.setState({ [e.target.name]: e.target.value });
  render() {
    return (
      <footer className="repair-footer-area" style={{ marginTop: "50px" }}>
        <section className="repair-subscribe-area">
          <div className="container">
            <div className="repair-subscribe-content">
              {this.state.successMsg && (
                <h5 style={{ position: "absolute" }}>
                  We received your submission
                </h5>
              )}
              <h2>Request a Demo</h2>

              <ValidationForm
                id="cform"
                onSubmit={(e, formData, inputs) => {
                  e.preventDefault();
                  this.handleForm(formData);
                }}
              >
                <div className="form-group">
                  <TextInput
                    name="email"
                    id="email"
                    type="email"
                    required
                    successMessage=""
                    errorMessage="Please enter your email address"
                    className="input-newsletter-footer"
                    placeholder="Email*"
                    autoComplete="off"
                    onChange={this.handleFields}
                  ></TextInput>
                </div>
                <div className="form-group" style={{ width: "50%" }}>
                  <TextInput
                    name="message"
                    id="message"
                    type="message"
                    required
                    successMessage=""
                    errorMessage="Please enter your message"
                    className="input-newsletter-footer"
                    placeholder="Message*"
                    autoComplete="off"
                    onChange={this.handleFields}
                  ></TextInput>
                </div>
                <button className="btn btn-primary" style={{ float: "right" }}>
                  Request a Demo <i className="icofont-arrow-right"></i>
                </button>
              </ValidationForm>
              {/* 
                            <form className="newsletter-form" data-toggle="validator" onSubmit={(e) =>{
                                console.log(e);
                                console.log(e.target);
                                console.log(e.target.value);
                            }}>
                                

                                <div className="form-group">
                                    <input
                                        type="email"
                                        className="input-newsletter"
                                        placeholder="Email Address*"
                                        name="EMAIL"
                                        required={true}
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="form-group newsletter-message">
                                    <input
                                        type="text"
                                        className="input-newsletter"
                                        placeholder="Message"
                                        name="message"
                                        required={true}
                                        autoComplete="off"
                                    />
                                </div>

                                <button className="btn btn-primary">Request a Demo <i className="icofont-arrow-right"></i></button>
                                <div id="validator-newsletter" className="form-result"></div>
                            </form> */}
            </div>
          </div>
        </section>

        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-footer-widget">
                <h3>Contact</h3>
                <ul className="contact-list">
                  <li>
                    <span>Address:</span> 1751 Pinnacle Drive, Suite 1100,
                    McLean, Virginia 22102
                  </li>
                  <li>
                    <span>Email:</span>{" "}
                    <a href={"mailto:info@leguard.com"}>info@leguard.com</a>
                  </li>
                </ul>
                <br />
                <ul className="social-links">
                  {/* <li><Link to="#"><i className="icofont-facebook"></i></Link></li> */}
                  {/* <li><a href="https://twitter.com/leguard_inc" target="blank"><i className="icofont-twitter"></i></a></li> */}
                  {/* <li><Link to="#"><i className="icofont-instagram"></i></Link></li> */}
                  <li>
                    <a
                      href="https://www.linkedin.com/company/leguard/"
                      target="blank"
                    >
                      <i className="icofont-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-footer-widget">
                <h3>About us</h3>

                <ul className="services-list">
                  <li>
                    <Link to="/about">Why LeGuard?</Link>
                  </li>
                  {/* <li><Link to="/faq">FAQs</Link></li> */}
                  {/* <li><Link to="/team">Team</Link></li> */}
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-footer-widget">
                <h3>
                  Download LeGuard and get <br />
                  Legal Spend Integrity®
                </h3>

                <img src={applestore} style={{ width: "50%" }}></img>
                <br />
                <br />
                <img src={playstore} style={{ width: "50%" }}></img>
              </div>
            </div>

            {/* <div className="col-lg-3 col-md-6">
                            <div className="single-footer-widget">
                                <h3>Working Hours</h3>

                                <ul className="working-hours">
                                    <li><span>Monday:</span> 8AM - 6PM</li>
                                    <li><span>Tuesday:</span> 8AM - 6PM</li>
                                    <li><span>Wednesday:</span> 8AM - 6PM</li>
                                    <li><span>Thursday - Friday:</span> 8AM - 6PM</li>
                                    <li><span>Sunday:</span> Closed</li>
                                </ul>
                            </div>
                        </div> */}
          </div>
        </div>

        <div className="repair-copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <p>Copyright @2021 LeGuard, Inc. All Rights Reserved.</p>
              </div>

              <div className="col-lg-6 col-md-6 text-right">
                <ul>
                  <li>
                    <Link
                      to="#"
                      onClick={() =>
                        this.setState({ showTerms: !this.state.showTerms })
                      }
                    >
                      Terms & Conditions
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={() =>
                        this.setState({ showPolicy: !this.state.showPolicy })
                      }
                    >
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <Modal
          size="lg"
          show={this.state.showTerms}
          onHide={() => this.setState({ showTerms: !this.state.showTerms })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Terms & Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p align="center">
              <strong>
                <u></u>
              </strong>
            </p>
            <p align="center">
              <strong>
                <u>Terms and Conditions for Users</u>
              </strong>
            </p>
            <p>
              Welcome to LeGuard. We are glad that you are using our service and
              hope that you enjoy all the benefits that it provides. Please read
              these terms carefully since by registering with and using LeGuard,
              you are accepting and agreeing to the following Terms and
              Conditions, which have legal effect, including but not limited to,
              a notice provision, limitations of liability, and time limitations
              on claims. Your acceptance of these Terms and Conditions
              constitutes an acknowledgment that you have read and understand
              all the provisions herein.
            </p>
            <p>
              I. <u>Definitions Used in these Terms and Conditions</u>
            </p>
            <p>
              <u></u>
            </p>
            <p>
              “Client” is the purchaser of LeGuard Inc.’s products and/or
              services. <u></u>
            </p>
            <p>
              <u></u>
            </p>
            <p>
              “Law Firm” is the entity or group providing substantive legal or
              other services to a Client, and whose users will use or administer
              the LeGuard software as a service.<u></u>
            </p>
            <p>
              <u></u>
            </p>
            <p>
              “LeGuard” is the software as a service provided by LeGuard, Inc.
              <u></u>
            </p>
            <p>
              <u></u>
            </p>
            <p>
              “LeGuard, Inc.” or “we” is the corporation providing a limited
              right for use of the LeGuard software as a service by Client, Law
              Firm and You. <u></u>
            </p>
            <p>
              “Matter” is a Client’s specific legal case or project for which a
              Law Firm’s and/or Client’s users perform substantive legal or
              other services and track their time via LeGuard.<u></u>
            </p>
            <p>
              “Order Form” is the contract formed between Client and/or Law Firm
              and LeGuard, Inc. <u></u>
            </p>
            <p>
              “Party” or “party” is You or LeGuard, Inc. “Parties” or “parties”
              is both You and LeGuard, Inc.<u></u>
            </p>
            <p>
              “You” or “Your” is anyone registering in or using LeGuard
              including, without limitation, any Client or Law Firm user or
              administrator.<u></u>
            </p>
            <p>
              <u></u>
            </p>
            <p>
              II. <u>Limited Right to Access; Maintenance Support; Ownership</u>
            </p>
            <p>
              As stated in the Client’s or Law Firm’s Order Form, Client’s or
              Law Firm’s purchase of products or services from LeGuard, Inc.
              grants Client or Law Firm a limited right (which is
              non-transferable and non-exclusive) to access and use LeGuard, and
              to direct and designate Law Firm and Client users or
              administrators such as You to access and use LeGuard. So long as
              Client’s or Law Firm’s account is current and Order Form is in
              effect, LeGuard, Inc. shall exercise commercially reasonable
              efforts to provide maintenance and support of LeGuard for Your
              use. In our constant effort to improve our service, LeGuard, Inc.
              reserves the right at any time, with or without notice to You, to
              modify or discontinue, temporarily or permanently, LeGuard
              services or features. LeGuard, Inc. shall not be liable to You or
              any third party for any modification, suspension, or
              discontinuance of LeGuard services or any feature. To protect the
              integrity of the LeGuard Inc. product and services, including,
              without limitation, from denial of service attacks, or illegal or
              inappropriate usage, LeGuard, Inc. reserves the right at any time
              in its sole discretion to block users from certain IP addresses to
              access LeGuard.<u></u>
            </p>
            <p>
              LeGuard, Inc. owns all rights, title, and interest, including all
              related intellectual and proprietary property rights, in and to
              LeGuard’s technology, content, and services. LeGuard, Inc. is, and
              shall be, the sole owner of all copyrights, trademarks, trade
              names, service marks, patents, inventions, discoveries, updates,
              improvements, modifications, enhancements, and other intellectual
              property<a name="_Hlk40256004"> </a>
              comprising or relating to LeGuard and LeGuard, Inc.’s product and
              services (collectively hereinafter “Intellectual Property”) , and
              retains the exclusive right to reproduce, publish, patent,
              copyright, trademark, sell, license, or otherwise make use of such
              Intellectual Property. The LeGuard name, logo, and the product
              names associated with LeGuard’s product and services are
              trademarks of LeGuard, Inc., and no right or license is granted to
              You to use them. You agree not to copy, modify, transmit, create
              any derivative works from, make use of, or reproduce in any way
              any copyrighted material, trademarks, trade names, service marks,
              or other intellectual property or proprietary information
              accessible through, relating to, or associated with LeGuard, or to
              facilitate anyone else doing the same. You agree not to remove,
              obscure, or otherwise alter any proprietary notices appearing on
              any content, including copyright, trademark, and other
              intellectual property notices. By these Terms and Conditions, You
              grant LeGuard, Inc. a world-wide, perpetual, irrevocable,
              royalty-free license to use and incorporate into LeGuard any
              suggestion, enhancement request, recommendation, correction or
              other feedback provided by You relating to LeGuard and its
              operation.
            </p>
            <p>
              III. <u>Disclaimer of Warranty </u>
            </p>
            <p>
              We provide LeGuard using a commercially reasonable level of care
              and promise to try our best to make sure you enjoy the use of
              LeGuard. But there are certain things that we do not promise about
              LeGuard.
            </p>
            <p>
              OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS AND CONDITIONS,
              NEITHER LEGUARD, INC. NOR ITS AUTHORIZED REPRESENTATIVES MAKE ANY
              SPECIFIC PROMISES ABOUT LEGUARD. FOR EXAMPLE, WE DON’T MAKE ANY
              COMMITMENTS ABOUT THE SPECIFIC FUNCTION OF LEGUARD, OR ITS
              RELIABILITY, AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS. WE
              PROVIDE LEGUARD “AS IS”. SOME JURISDICTIONS PROVIDE FOR CERTAIN
              WARRANTIES, LIKE THE IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS
              FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, OR ACCURACY.
              LEGUARD, INC. DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN
              LEGUARD WILL MEET YOUR REQUIREMENTS, THAT THE OPERATION OF LEGUARD
              WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT DEFECTS IN LEGUARD
              WILL BE CORRECTED. TO THE EXTENT PERMITTED BY LAW, WE EXCLUDE ALL
              WARRANTIES. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY
              LEGUARD, INC. OR A LEGUARD, INC. AUTHORIZED REPRESENTATIVE SHALL
              CREATE A WARRANTY.
            </p>
            <p>
              ANY MATERIAL OR INFORMATION OBTAINED BY YOU THROUGH THE USE OF
              LEGUARD IS ACCESSED AT YOUR OWN DISCRETION AND RISK, AND YOU WILL
              BE SOLELY RESPONSIBLE FOR AND HEREBY WAIVE ANY AND ALL CLAIMS AND
              CAUSES OF ACTION WITH RESPECT TO ANY DAMAGE TO YOUR DEVICE,
              COMPUTER SYSTEM, INTERNET ACCESS, DISPLAY DEVICE, OR LOSS OR
              CORRUPTION OF DATA THAT RESULTS OR MAY RESULT FROM THE USE OF
              LEGUARD AND/OR OBTAINING OF ANY SUCH MATERIAL OR INFORMATION. IF
              YOU DO NOT ACCEPT THIS LIMITATION OF LIABILITY, YOU ARE NOT
              AUTHORIZED TO USE OR OBTAIN ANY MATERIAL OR INFORMATION THROUGH
              LEGUARD.
            </p>
            <p>
              SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED
              WARRANTIES OR LIMITATIONS ON APPLICABLE STATUTORY RIGHTS OF A
              CONSUMER, SO THE ABOVE EXCLUSION AND LIMITATIONS MAY NOT APPLY TO
              YOU.<u></u>
            </p>
            <p>
              <u></u>
            </p>
            <p>
              IV. <u>Authorized Usage</u>
            </p>
            <p>
              <u></u>
            </p>
            <p>
              Please do not use LeGuard in a way that violates any laws,
              infringes on anyone’s rights, is offensive, or interferes with
              LeGuard or any features of LeGuard and its use. By Your
              registration, LeGuard’s use is only for You, and you shall not,
              directly or indirectly, let or facilitate anyone in using LeGuard
              with your credentials besides You. And we need you to bear sole
              responsibility for ensuring that You restrict your usage of
              LeGuard as stated herein and to its authorized purpose of tracking
              fees and time on a live basis and viewing and administering the
              same. If we determine (in our sole discretion) that You have acted
              inappropriately, we reserve the right to prohibit You from using
              LeGuard and to take appropriate legal actions.
            </p>
            <p>
              V. <u>Accuracy of Account Information</u>
            </p>
            <p>
              <u></u>
            </p>
            <p>
              You are responsible for maintaining up-to-date, accurate, and
              truthful account information, including, but not limited to, names
              of Law Firm or Client user or administrator names, rates, email
              addresses and other such information (as applicable and
              hereinafter “Account Information”), and shall update such Account
              Information to be current and accurate on a regular basis. If the
              Account Information provided by You is false or fraudulent,
              LeGuard, Inc. reserves the right to suspend or terminate Your
              access to LeGuard, in addition to seeking any other legal or
              equitable remedies.
            </p>
            <p>
              VI. <u>Internet Connection and Security</u>
            </p>
            <p>
              <u></u>
            </p>
            <p>
              An Internet connection is required to access certain features of
              LeGuard. You will need to provide your own Internet access at your
              expense to use LeGuard. The LeGuard product and services may be
              subject to, and LeGuard, Inc. cannot be responsible for, any
              losses, claims, costs, penalties, fees, expenses, damages
              (including but not limited to special, consequential and punitive
              damages), fines, charges, liens, injuries, debts, liabilities, and
              expenses of every kind, including but not limited to reasonable
              attorneys’ fees and courts costs, and/or similar charges
              (collectively, “Costs”) of any limitations, delays, and other
              problems inherent in the use of the Internet and electronic
              communications.<u></u>
            </p>
            <p>
              <u></u>
            </p>
            <p>
              VII. <u>No Warranty of Fee-Tracking Accuracy</u>
            </p>
            <p>
              <u></u>
            </p>
            <p>
              You bear sole responsibility for the accuracy of the fee and time
              tracking information inputted by You as a Law Firm or Client user
              via LeGuard (i.e., Matters, start and stop times, activity
              descriptions, rates, etc.). You agree that LeGuard, Inc. is not
              responsible for any failure by You to utilize LeGuard and/or input
              information in a diligent, timely and accurate manner to track
              fees live. <u></u>
            </p>
            <p>
              <u></u>
            </p>
            <p>
              VIII. <u>Governing Law</u>
            </p>
            <p>
              <u></u>
            </p>
            <p>
              These Terms and Conditions shall be construed and interpreted
              under, and governed by, the substantive laws of the State of New
              York without regard to the choice or conflicts of law provisions
              of any jurisdiction which would apply the laws of another
              jurisdiction.
            </p>
            <p>
              IX. <u>Exclusive Choice of Forum</u>
            </p>
            <p>
              <u></u>
            </p>
            <p>
              Any claims, disputes, lawsuits, demands, administrative
              proceedings or causes of action (“Action”) arising from or
              relating to You or anyone who You, directly or indirectly, let or
              facilitate using LeGuard including, without limitation, any other
              user or administrator (collectively, “Your Indemnitors”), and
              LeGuard and/or LeGuard, Inc. can only and exclusively be brought
              in a court of competent jurisdiction located in the Commonwealth
              of Virginia (which is LeGuard, Inc.’s principal place of business
              and state of incorporation, and the locale where the services are
              rendered). You and Your Indemnitors hereby consent to jurisdiction
              of the courts within the Commonwealth of Virginia AS THE SOLE AND
              EXCLUSIVE FORUM for any Action, and agree that any process,
              notice, motion, or other application to the courts within the
              Commonwealth of Virginia may be served outside the Commonwealth of
              Virginia by registered mail or by personal service, so long as the
              method used provides a reasonable time for appearance.
            </p>
            <p>
              X. <u>LIMITATION OF ACTIONS</u>
            </p>
            <p>
              YOU AGREE THAT REGARDLESS OF ANY STATUTE OR LAW TO THE CONTRARY,
              ANY ACTION MUST BE FILED WITHIN ONE YEAR AFTER SUCH ACTION
              ACCRUED, OR SUCH ACTION IS FOREVER BARRED. FOR CLARIFICATION,
              ACTION IS A DEFINED TERM ABOVE.
            </p>
            <p>
              XI. <u>Attorneys’ Fees and Costs; Prevailing Party Provision</u>
            </p>
            <p>
              <u></u>
            </p>
            <p>
              In the event that an Action or other legal matter between LeGuard,
              Inc. and You is instituted, the substantially prevailing party in
              such dispute shall be entitled to recover any and all Costs, from
              the non-prevailing party including, without limitation, all Costs
              of appeals.
            </p>
            <p>
              XII. <u>Force Majeure</u>
            </p>
            <p>
              <u></u>
            </p>
            <p>
              If LeGuard Inc.’s performance of any part of these Terms and
              Conditions, or the performance of LeGuard, or the provision of
              services hereunder is prevented, hindered, delayed, or otherwise
              made impracticable due to anything outside LeGuard, Inc.’s
              reasonable control including, without limitation, any strike or
              other labor problem, flood, fire, explosion, war, civil unrest,
              act of God, act of terrorism, act of civil authority, act of
              vandalism, or technology failure (including network
              intrusions/failures/delays, denial of service attacks, and
              internet connectivity issues, computer or other technological
              failures involving hardware or software not within LeGuard, Inc.’s
              possession or reasonable control), LeGuard, Inc. shall be excused
              from performance to the extent that it is prevented, hindered or
              delayed. Provided LeGuard, Inc. takes reasonable steps to rectify
              the same, LeGuard, Inc. shall continue to be excused from
              performance and these Terms and Conditions shall be deemed
              suspended so long as and to the extent that any such cause shall
              operate to prevent, hinder, or delay the performance by LeGuard,
              Inc. of its obligations.
            </p>
            <p>
              XIII. <u>Indemnification</u>
            </p>
            <p>
              <u></u>
            </p>
            <p>
              You agree to indemnify, defend, protect and hold LeGuard, Inc. and
              its officers, directors, employees, agents, assigns, and
              successors in interest (collectively the “Indemnitees”) harmless{" "}
              <a name="_Hlk40250763">from and against any and all Costs, </a>
              arising from, relating to, or resulting from, in whole or in part:
              (1) the use of LeGuard by You or Your Indemnitors, (2) any breach,
              default, or violation of these Terms and Conditions by You or Your
              Indemnitors; and/or (3) violation of local, state, federal, or
              international rules, laws, codes, ordinances, regulations or
              treaties by You or Your Indemnitors.
            </p>
            <p>
              XIV.
              <u>
                Additional Disclaimers; Limitation of Liability; Waiver of
                Consequential Damages
              </u>
            </p>
            <p>
              <u></u>
            </p>
            <p>
              Law Firm, Client and You acknowledge and agree that neither
              LeGuard, Inc. nor its Indemnitees are responsible for, and such
              entities and individuals shall have no liability, directly or
              indirectly, for any Action or Cost arising from, relating to, or
              resulting from: (1) any incorrect or inaccurate content,
              information, or data inputted via LeGuard by Client, Law Firm, You
              or Your Indemnitors; (2) any error, omission or defect in,
              interruption, deletion, alteration, delay in operation or
              transmission, theft or destruction of, or unauthorized access to,
              any content inputted by a LeGuard user or administrator or such
              user or administrator communications; or (3) any problems,
              failure, or technical malfunction of any telephone network or
              lines, computer online systems, servers or providers, computer
              equipment, software, or LeGuard services on account of technical
              problems or traffic congestion on the Internet.
            </p>
            <p>
              YOU AGREE AND ACKNOWLEDGE THAT LEGUARD, INC.’S AND ITS INDEMNITEES
              LIABILITY WITH RESPECT TO ANY ACTION, OR OTHER LEGAL MATTER THAT
              IS BROUGHT AGAINST LEGUARD, INC. BY YOU SHALL BE LIMITED TO
              $10,000.
            </p>
            <p>
              IN NO EVENT SHALL LEGUARD, INC. OR THE INDEMNITEES BE LIABLE TO
              YOU OR YOUR INDEMNITORS FOR ANY INDIRECT, PUNITIVE, SPECIAL,
              EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES OF SIMILAR
              TYPE OR KIND (INCLUDING LOSS OF DATA, REVENUE, PROFITS, USE OR
              OTHER ECONOMIC ADVANTAGE).
            </p>
            <p>
              XV.{" "}
              <u>LeGuard’s Suspension or Termination for Breach or Default</u>
            </p>
            <p>
              <u></u>
            </p>
            <p>
              If You or Your Indemnitors breach or default upon any of the
              obligations set forth in these Terms and Conditions, LeGuard, Inc.
              has the exclusive and sole right to suspend or terminate Your
              access to LeGuard products and services. In the event of such
              suspension or termination, LeGuard, Inc. shall not be responsible
              for any Costs related to suspension, interruption, termination or
              cessation of Law Firm’s or Your fee-tracking. These Terms and
              Conditions survive such suspension or termination, and continue in
              full force and effect, except for any terms that, by their nature,
              expire or are fully satisfied.
            </p>
            <p>
              XVI. <u>Severability</u>
            </p>
            <p>
              <u></u>
            </p>
            <p>
              If any clause or provision of these Terms and Conditions shall be
              held invalid or unenforceable, in whole or in part, in any
              jurisdiction, such invalidity or unenforceability shall attach
              only to such clause or provision, or part thereof, and shall not
              in any manner affect any other clause or provision in any
              jurisdiction.
            </p>
            <p>
              XVII. <u>Notice</u>
            </p>
            <p>
              <u></u>
            </p>
            <p>
              All notices under these Terms and Conditions shall be in writing
              and shall be deemed to be delivered five (5) business days after
              being deposited in the United States Postal Service, postage
              prepaid, the next business day if sent by recognized overnight
              courier, or when sent by e-mail (with proof of electronic
              delivery). Notices to LeGuard, Inc. shall be addressed to:
              LeGuard, Inc., 1751 Pinnacle Drive, Suite 1100, McLean, VA 22102.
              Notices to You shall be sent to the email address listed for You
              in LeGuard.
            </p>
            <p>
              XVIII. <u>Complete Agreement; Waiver</u>
            </p>
            <p>
              <u></u>
            </p>
            <p>
              These Terms and Conditions constitutes the complete and exclusive
              agreement between You and LeGuard, Inc. and supersedes all prior
              express or implied agreements or understandings between the
              Parties concerning LeGuard and its use.
            </p>
            <p>
              Any waiver by LeGuard, Inc. of any performance by You of any of
              LeGuard, Inc.’s rights under these Terms and Conditions, shall not
              be, and shall not be deemed to be, a waiver of any subsequent
              obligation of performance, default, or right of the same or
              similar kind.
            </p>
            <p>
              XIX. <u>Non-Assignment</u>
            </p>
            <p>
              <u></u>
            </p>
            <p>
              You may not assign any of your rights or obligations under these
              Terms and Conditions to any third party or entity, and any rights
              or obligations hereunder may not be involuntarily assigned or
              assigned by operation of law, without the prior written consent of
              LeGuard, Inc.
            </p>
            <p>
              XX. <u>Authority</u>
            </p>
            <p>
              <u></u>
            </p>
            <p>
              You represent and warrant that you have the legal power and
              authority to accept these Terms and Conditions.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() =>
                this.setState({ showTerms: !this.state.showTerms })
              }
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="lg"
          show={this.state.showPolicy}
          onHide={() => this.setState({ showPolicy: !this.state.showPolicy })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Privacy Policy</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p align="center">
              <strong>Privacy Policy</strong>
            </p>
            <p align="center">
              <strong>Current Update - December 2020</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              This Privacy Policy explains how we collect, use, disclose, and
              safeguard your information when you visit our website{" "}
              <a href="http://www.leguard.com/">www.leguard.com</a>, including
              any other media form, media channel, mobile website, or mobile
              application related or connected thereto (collectively, the
              &ldquo;Site&rdquo;).&nbsp;
            </p>
            <p>&nbsp;</p>
            <p>
              Please read this privacy policy carefully.&nbsp; If you do not
              agree with the terms of this privacy policy, please do not access
              the Site.&nbsp;
            </p>
            <p>&nbsp;</p>
            <p>
              We reserve the right to make changes to this Privacy Policy at any
              time and for any reason.&nbsp; We will alert you of any changes by
              updating the &ldquo;Current Update&rdquo; date of this Privacy
              Policy.&nbsp; Any changes or modifications will be effective
              immediately upon posting the updated Privacy Policy on the Site,
              and you waive the right to receive specific notice of each such
              change or modification.&nbsp;
            </p>
            <p>
              You are encouraged to periodically review this Privacy Policy to
              stay informed of updates. You will be deemed to have been made
              aware of, will be subject to, and will be deemed to have accepted
              the changes in any revised Privacy Policy by your continued use of
              the Site after the date such revised Privacy Policy is
              posted.&nbsp;&nbsp;
            </p>
            <p>
              The following are the key areas we discuss in this Privacy Policy:
            </p>
            <ul>
              <li>
                What personally identifiable information is collected from our
                users.
              </li>
              <li>Where personally identifiable information is collected.</li>
              <li>
                How we use personally identifiable information.&nbsp;&nbsp;
              </li>
              <li>
                Whom personally identifiable information is shared with.&nbsp;
              </li>
              <li>
                What rights users have over their personally identifiable
                information.
              </li>
              <li>
                What types of security procedures are in place to protect the
                loss, misuse or alteration of information.
              </li>
              <li>LeGuard&rsquo;s contact details.</li>
            </ul>
            <p>&nbsp;</p>
            <p align="center">
              <strong>
                WHAT PERSONALLY IDENTIFIABLE INFORMATION WE COLLECT
              </strong>
            </p>
            <p>
              <strong>Personal Data&nbsp;</strong>
            </p>
            <p>
              LeGuard, Inc. may collect and store information that can identify
              Client, Law Firm and your personnel by name and email address, as
              well as other information such as address and telephone number.
              LeGuard,&nbsp;Inc. collects financial information relating to
              &ldquo;Matters&rdquo; that a Law Firm works on and for which the
              Law Firm bills time to the Client. This information includes
              budgets and fees accrued by Authorized Billers against both past
              and current Matters. LeGuard,&nbsp;Inc. may collect this
              information through the Site. By purchasing a subscription plan
              and/or using LeGuard, you authorize LeGuard, Inc. to gather,
              synthesize, and retain data related to the provision of LeGuard.
            </p>
            <p>
              <strong>Derivative Data&nbsp;</strong>
            </p>
            <p>
              Information our servers automatically collect when you access the
              Site, such as your IP address, your browser type, your operating
              system, your access times, and the pages you have viewed directly
              before and after accessing the Site.&nbsp;
            </p>
            <p>
              <strong>Mobile Device Data&nbsp;</strong>
            </p>
            <p>
              Device information, such as your mobile device ID, model, and
              manufacturer, and information about the location of your device,
              if you access the Site from a mobile device.
            </p>
            <p>
              <strong>Aggregated Data</strong>
            </p>
            <p>
              You own and retain all rights, title and interest in the
              information collected about you by LeGuard, Inc. through
              LeGuard.&nbsp; Subject to the limited rights expressly and
              specifically granted in this Privacy Policy, LeGuard, Inc.
              acquires no rights, title or interest from you under the Order
              Form or your use of LeGuard in or to your information collected by
              LeGuard, Inc. through LeGuard or the Site. You give LeGuard, Inc.
              the limited right and license to collect Client&rsquo;s and Law
              Firm&rsquo;s, including your, information via LeGuard or the Site
              and use such information, solely in aggregated form that does not
              identify Client, Law Firm, any individual or entity, or any
              specific transaction of Client or any other individual or entity
              (&ldquo;Aggregated Data&rdquo;). For clarification, LeGuard, Inc.
              will own the aggregated and statistical data derived from the
              operation of LeGuard and the Site that does not reveal the
              identity, whether directly or indirectly, of any individual or
              specific data entered by any individual into LeGuard and the
              Site.&nbsp; In no event does the Aggregated Data include any
              personally identifiable information. LeGuard, Inc. may prepare
              statistical analyses, reports and summary of usage or provide
              Aggregated Data to others for, among other reasons and without
              limitation, the purpose of research, marketing and
              benchmarking.&nbsp; Nothing herein shall be construed as
              prohibiting LeGuard, Inc. from utilizing the Aggregated Data for
              purposes of operating LeGuard, Inc.&rsquo;s business.
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <p align="center">
              <strong>WHERE WE MAY COLLECT YOUR INFORMATION</strong>
            </p>
            <p>
              We may collect information about you in a variety of ways. The
              information we may collect on the Site or by LeGuard includes:
            </p>
            <p>
              <strong>Registration</strong>
              <br /> In order to use this Site, a user must first complete the
              registration form. During registration, a user is required to give
              contact information (such as name, work email address, position,
              mailing address, and billing rates). We use this information to
              allow the users to access and use the services of our Site.
            </p>
            <p>
              <strong>Contact Us</strong>
            </p>
            <p>
              You may provide information to us through our Contact Us page or{" "}
              <a href="mailto:info@leguard.com">info@leguard.com</a>. Please do
              not provide any sensitive or private information in this area.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Profile</strong>
            </p>
            <p>
              We store information that we collect through cookies and log
              files, and to create a profile of our users. A profile is stored
              information that we keep on individual users to have the Site
              function. For example, a work email is necessary to log in to
              LeGuard.&nbsp; Consequently, collected information will be tied to
              the users personally identifiable information to utilize LeGuard.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Cookies and Web Beacons</strong>
            </p>
            <p>
              When you use the Site, LeGuard, Inc. may assign a device one or
              more cookies, web beacons, tracking pixels, and other tracking
              technologies to facilitate access to the Site and to personalize
              your experience. Using these tracking technologies, LeGuard, Inc.
              also may automatically collect information about your activity on
              the Site. When you access the Site, your personal information is
              not collected using tracking technology. Most browsers are set to
              accept cookies by default. You can remove or reject cookies but be
              aware that such action could affect the availability and
              functionality of the Site. You may not decline web beacons.
              However, they can be rendered ineffective by declining all cookies
              or by modifying your web browser&rsquo;s settings to notify you
              each time a cookie is tendered, permitting you to accept or
              decline cookies on an individual basis.
            </p>
            <p>
              <strong>Website Analytics&nbsp;</strong>
            </p>
            <p>
              We may also partner with selected third-party vendors such to
              allow tracking technologies on the Site through the use of first
              party cookies and third-party cookies, to, among other things,
              analyze and track users&rsquo; use of the Site and better
              understand online activity. By accessing the Site, you consent to
              the collection and use of your information by these third-party
              vendors. You are encouraged to review their privacy policy and
              contact them directly for responses to your questions. We do not
              transfer personal information to these third-party vendors.&nbsp;
            </p>
            <p>
              <strong>Third Party Websites</strong>
            </p>
            <p>
              The Site may contain links to third-party websites and
              applications of interest, including advertisements and external
              services, that are not affiliated with us. Once you have used
              these links to leave the Site any information you provide to these
              third parties is not covered by this Privacy Policy, and we cannot
              guarantee the safety and privacy of your information. Before
              visiting and providing any information to any third-party
              websites, you should inform yourself of the privacy policies and
              practices (if any) of the third party responsible for that
              website, and should take those steps necessary to, in your
              discretion, protect the privacy of your information. We are not
              responsible for the content or privacy and security practices and
              policies of any third parties, including other sites, services or
              applications that may be linked to or from the Site.
            </p>
            <p>
              <strong>Log Files</strong>
            </p>
            <p>
              Like most standard Web site servers, we use log files. This
              includes internet protocol (IP) addresses, browser type, internet
              service provider (ISP), referring/exit pages, platform type,
              date/time stamp, and number of clicks to analyze trends,
              administer the Site, track user&rsquo;s movement in the aggregate,
              and gather broad demographic information for aggregate use. IP
              addresses, etc. are not linked to personally identifiable
              information, and are never given to third parties.
            </p>
            <p>&nbsp;</p>
            <p align="center">
              <strong>
                HOW WE MAY USE PERSONALLY IDENTIFIABLE INFORMATION
              </strong>
            </p>
            <p>
              Having accurate information about you permits us to provide you
              with a smooth, efficient, and customized experience. Specifically,
              we may use information collected about you via the Site to:&nbsp;
            </p>
            <ul>
              <li>
                Deliver and improve LeGuard, Inc. products and services, and
                manage LeGuard, Inc.&rsquo;s business.
              </li>
              <li>Manage your account and provide customer support.</li>
              <li>Email or otherwise contact you regarding your account.</li>
              <li>
                Perform research and analysis about your use of, or interest in,
                LeGuard, Inc.&rsquo;s products, services, or content.
              </li>
              <li>
                Communicate with you by email, postal mail, telephone, and/or
                mobile devices.
              </li>
              <li>Perform website or mobile application analytics.</li>
              <li>Increase the efficiency and operation of the Site.</li>
              <li>
                Enforce or exercise any rights in LeGuard, Inc.&rsquo;s Terms
                and Conditions.
              </li>
              <li>
                Perform functions or services as otherwise described to you at
                the time of performance.
              </li>
              <li>
                Research, marketing and benchmarking of Aggregated Data as noted
                above.
              </li>
              <li>
                Notify you of service announcements regarding the Site or your
                account or of any updates.
              </li>
              <li>Offer recommendations to you about the use of the Site.</li>
              <li>Perform other business activities as needed.</li>
              <li>
                Request feedback and contact you about your use of the
                Site.&nbsp;
              </li>
              <li>Troubleshoot problems.</li>
            </ul>
            <p>&nbsp;</p>
            <p align="center">
              <strong>WHO PERSONALLY IDENTIFIABLE INFORMATION</strong>
            </p>
            <p align="center">
              <strong>&nbsp;IS SHARED WITH</strong>
            </p>
            <p>
              We may share information we have collected about you in certain
              situations. Your information may be disclosed as
              follows:&nbsp;&nbsp;
            </p>
            <p>
              -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; LeGuard, Inc.
              may share Client provided and Law-Firm provided information and
              information provided by you with service providers such as third
              parties, which perform activities on LeGuard, Inc.&rsquo;s behalf
              such as web hosting services. These service providers may have
              access to Client and Law Firm information needed to perform their
              functions but are not permitted to share or use such information
              for any other purposes.&nbsp;
            </p>
            <p>
              -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; LeGuard, Inc.
              further may be required to share Client, Law Firm and your
              information: (1) in response to a subpoena or similar
              investigative demand, a court order, or a request for cooperation
              from a law enforcement or other government agency; (2) to
              establish or exercise legal rights; (3) to defend against legal
              claims; or (4) as otherwise required by law. In such cases,
              LeGuard, Inc. may raise or waive any legal objection or right
              available to it.&nbsp;
            </p>
            <p>
              -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; LeGuard, Inc.
              further may disclose Client, Law Firm or your information when it
              believes disclosure is appropriate in connection with efforts: (1)
              to investigate, prevent, or take other action regarding illegal
              activity, suspected fraud or other wrongdoing; (2) to protect and
              defend the rights, property or safety of LeGuard, Inc., Clients,
              Law Firms, employees, or others; (3) to comply with applicable law
              or cooperate with law enforcement; or (4) to enforce LeGuard,
              Inc.&rsquo;s Terms and Conditions, order forms, or other
              agreements or policies.
            </p>
            <p>
              -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; As contemplated
              by Section XXIV, C.1, of the Terms and Conditions.
            </p>
            <p>
              -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; If an Order Form
              is terminated, LeGuard, Inc. will retain certain information
              associated with you for analytical purposes and recordkeeping
              integrity, as well as to prevent fraud, enforce these Terms and
              Conditions, take actions deemed necessary to protect the integrity
              of the Site and LeGuard, Inc., or take other actions otherwise
              permitted by law. In addition, if certain information has already
              been provided to third parties as described in this Privacy
              Policy, retention of that information will be subject to those
              third parties' policies.
            </p>
            <p>&nbsp;</p>
            <p align="center">
              <strong>WHAT RIGHTS USERS HAVE OVER THEIR&nbsp;</strong>
            </p>
            <p align="center">
              <strong>PERSONALLY IDENTIFIABLE INFORMATION</strong>
            </p>
            <p>
              <strong>CONTROLS FOR DO-NOT-TRACK FEATURES&nbsp;&nbsp;</strong>
            </p>
            <p>
              Most web browsers and some mobile operating systems include a
              Do-Not-Track (&ldquo;DNT&rdquo;) feature or setting you can
              activate to signal your privacy preference not to have data about
              your online browsing activities monitored and collected.&nbsp; No
              uniform technology standard for recognizing and implementing DNT
              signals has been finalized. As such, we do not currently respond
              to DNT browser signals or any other mechanism that automatically
              communicates your choice not to be tracked online.&nbsp; If a
              standard for online tracking is adopted that we must follow in the
              future, we will inform you about that practice in a revised
              version of this Privacy Policy.&nbsp;
            </p>
            <p>
              <strong>OPTIONS REGARDING YOUR INFORMATION</strong>
            </p>
            <p>
              <strong>Account Information</strong>
            </p>
            <p>
              You may at any time review or change the information in your
              account or terminate your account by:
            </p>
            <ul>
              <li>
                Logging into your account settings and updating your account
              </li>
              <li>Contacting us at info@leguard.com</li>
            </ul>
            <p>&nbsp;</p>
            <p>
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, some information may be retained in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our Terms of Use and/or comply with legal requirements.
            </p>
            <p>
              <strong>Emails and Communications</strong>
            </p>
            <p>
              If you no longer wish to receive correspondence, emails, or other
              communications from us, you may opt-out by:
            </p>
            <ul>
              <li>Contacting us at info@leguard.com.</li>
              <li>Opt Out in the communications themselves, if applicable.</li>
            </ul>
            <p>Please note that this may impact your use of the Site.</p>
            <p>
              If you no longer wish to receive correspondence, emails, or other
              communications from third parties, you are responsible for
              contacting the third party directly.
            </p>
            <p>
              <strong>Changes to this Privacy Policy</strong>
            </p>
            <p>
              LeGuard, Inc. will occasionally update its Privacy Policy; all
              revisions will be posted and noted on the Site. For any questions,
              please contact LeGuard, Inc. at{" "}
              <a href="mailto:info@LeGuard.com">info@LeGuard.com</a>.
            </p>
            <p>&nbsp;</p>
            <p align="center">
              <strong>WHAT TYPES OF SECURITY PROCEDURES ARE IN PLACE</strong>
            </p>
            <p>
              We use administrative, technical, managed security service
              providers and physical security measures to help protect your
              personal information.&nbsp; While we have taken reasonable steps
              to secure the personal information you provide to us, please be
              aware that despite our efforts, no security measures are perfect
              or impenetrable, and no method of data transmission can be
              guaranteed against any interception or other type of misuse.&nbsp;
              Any information disclosed online is vulnerable to interception and
              misuse by unauthorized parties. Therefore, we cannot guarantee
              complete security if you provide personal information. In
              addition, LeGuard, Inc. does not control, and is not responsible
              or liable for, security issues caused by Client&rsquo;s, Law
              Firm&rsquo;s or your use of LeGuard. You are responsible for
              maintaining the confidentiality of Client and Law Firm information
              inputted, exchanged, and tracked via LeGuard and for maintaining
              the confidentiality of the password designated by you. You agree
              to immediately notify LeGuard, Inc. of any disclosure or
              unauthorized use of your password or any other breach of security
              and we will work with you to remedy the same to the extent
              reasonably practicable.
            </p>
            <p align="center">
              <strong>CONTACT US</strong>
            </p>
            <p>
              If you have questions or comments about this Privacy Policy,
              please contact us at{" "}
              <a href="mailto:info@leguard.com">info@leguard.com</a>.
            </p>
            <p>&nbsp;</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() =>
                this.setState({ showPolicy: !this.state.showPolicy })
              }
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="map-box">
          <img src={require("../../assets/img/footer-map.png")} alt="footer" />
        </div>
      </footer>
    );
  }
}

export default Footer;
