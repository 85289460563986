import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

class GoogleMap extends Component {
    render() {
        return (
            <Map google={this.props.google}  zoom={14} initialCenter={{
                lat: 38.920680,
                lng: -77.229080
            }}>
                <Marker
                    onClick={this.onMarkerClick}
                    name={"Current location"}
                />
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyA9GCVC7tKsdhlBU0HLkar9mlVSLKyWrgY"
})(GoogleMap);
