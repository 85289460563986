import React, { Component } from "react";

import NavBarMultiPage from "../common/NavBarMultiPage";
import Footer from "../versionSeven/Footer";
import img1 from "../../assets/img/report.png";
import clock from "../../assets/img/clock.png";
import circles from "../../assets/img/circles.png";
import lgdashboard from "../../assets/img/lgdashboard.png";
import lspImage from "../../assets/img/LSPPicture.png";

class Lawfirms extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <NavBarMultiPage pageName="contact" />
        <div className="page-title">
          <div className="pattern-2" />
          <div className="bg-top" />
          <div className="bg-bottom" />
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="page-title-content">
                  <h3>Legal Service Providers</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="general" className="about-area">
          <div className="section-title">
            <h2 style={{ textAlign: "center" }}>
              <u>LeGuard for Your Clients</u>
            </h2>
            <h3 style={{ maxWidth: "800px" }}>
              In today’s competitive legal market, legal service providers must
              retain profitable clients and offer distinctive advantages to
              prospective ones. LeGuard facilitates both!
            </h3>
          </div>
          <div className="repair-about-area ptb-100 leguard-full-width-div">
            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <div className="repair-about-content">
                    <div className="section-title">
                      <h3>Problems</h3>
                      <ul>
                        <li>
                          <i class="icofont-close-circled"></i>Limited,
                          after-the-fact visibility into activities and budget
                          consumption
                        </li>
                        <li>
                          <i class="icofont-close-circled"></i>Stressful,
                          awkward billing negotiations with clients
                        </li>
                        <li>
                          <i class="icofont-close-circled"></i>Need to write-off
                          hours in excess of budget/client expectations
                        </li>
                        <li>
                          <i class="icofont-close-circled"></i>Administrative
                          burden of keeping all clients up-to-date
                        </li>
                        <li>
                          <i class="icofont-close-circled"></i>Clients are
                          asking for more visibility
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4" style={{ textAlign: "center" }}>
                  <span className="helper"></span>
                  <img
                    src={clock}
                    style={{ width: "50%", verticalAlign: "middle" }}
                  ></img>
                </div>
                <div className="col-lg-4">
                  <div className="repair-about-content">
                    <div className="section-title">
                      <h3>Solved!</h3>
                      <ul>
                        <li>
                          <i class="icofont-check-circled"></i>Ability to impact
                          time/activities for more efficient, profitable work
                        </li>
                        <li>
                          <i class="icofont-check-circled"></i>Objective data
                          for fewer write offs = higher realization rates
                        </li>
                        <li>
                          <i class="icofont-check-circled"></i>Automatic budget
                          updates/alerts for Clients and Legal Service Providers
                        </li>
                        <li>
                          <i class="icofont-check-circled"></i>LIVE visibility
                          for up-to-the moment activity and spend tracking and
                          reporting
                        </li>
                        <li style={{ width: "100%" }}>
                          <i class="icofont-check-circled"></i>More transparancy
                          = stronger relationships with clients
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row mt-100">
              <div className="col-lg-6 col-md-6">
                <div className="img">
                  <img src={img1} alt="img" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="about-text mb-0">
                  <h3 style={{ color: "#941212" }}>
                    LeGuard Eliminates Billing Discrepancies and Reduces
                    Write-Offs
                  </h3>

                  <p>
                    With LeGuard, lawyers managing client matters have LIVE
                    visibility into their teams’ spend and activities so they
                    can track each biller’s workload, activities and billing
                    amount – anytime, anywhere. With this visibility, for the
                    first time, they can manage and engage their teams for the
                    most effective work possible.
                  </p>
                  <br />
                  <p>
                    Note: LeGuard can be used both by those working within the
                    legal service provider (attorneys, paralegals, etc.) and
                    outside the provider (expert witnesses, eDiscovery
                    personnel, consultants and others) designated to work on a
                    matter.
                  </p>
                  <br />
                  <p>
                    With data that is identical to what the clients see, legal
                    service providers have transparent and verifiable back-up
                    for their invoices at the end of the billing cycle – thus
                    eliminating billing discrepancies and reducing the need to
                    write off hours. And LeGuard is always keeping you and your
                    clients up to date on spend and activities without any
                    administrative time on either part.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row mt-100">
              <div className="col-lg-12" style={{ textAlign: "center" }}>
                <span className="helper"></span>
                <img src={lspImage}></img>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row mt-100">
              <div className="col-lg-6 col-md-6">
                <div className="about-text mb-0">
                  <p>
                    General Counsels and their legal departments need better and
                    more timely information. Financial surprise and lack of
                    budget control is one of their key issues/concerns. And the
                    legal service offerings market is more competitive than
                    ever. LeGuard helps you solve for both.
                  </p>
                  <br />
                  <p>
                    Legal Service Providers can use LeGuard internally (without
                    a client view) to see what your teams are doing, while they
                    are working, giving you live data to better manage your
                    matters. In today’s remote work environment this is more
                    important than ever. And offering a view of some or all of
                    your work to your clients can help you win new business,
                    proactively differentiate to keep existing business, and
                    strengthen your relationship through transparency and
                    communication.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="img">
                  <img src={lgdashboard} alt="img" />
                </div>
              </div>
            </div>
          </div>
          <div className="repair-about-area" style={{ paddingTop: "100px" }}>
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <div className="repair-about-content">
                    <div className="section-title">
                      <h3 style={{ color: "#941212" }}>
                        <u>For Your Clients</u>
                      </h3>
                      <ul>
                        <li>
                          <i class="icofont-tick-mark"></i>A competitive edge
                          for RFPs, keeping business
                        </li>
                        <li>
                          <i class="icofont-tick-mark"></i>Reduced end of month
                          financial surprises
                        </li>
                        <li>
                          <i class="icofont-tick-mark"></i>Stronger client
                          relationships through transparency and communication
                        </li>
                        <li>
                          <i class="icofont-tick-mark"></i>Better manage matters
                          with your clients
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-lg-6">
                  <div className="repair-about-content">
                    <div className="section-title">
                      <h3 style={{ color: "#941212" }}>
                        <u>For your internal team use</u>
                      </h3>
                      <ul>
                        <li>
                          <i class="icofont-tick-mark"></i>Better manage your
                          teams
                        </li>
                        <li>
                          <i class="icofont-tick-mark"></i>Know your spend
                          throughout the billing cycle
                        </li>
                        <li>
                          <i class="icofont-tick-mark"></i>Reduce write offs =
                          higher realization rates
                        </li>
                        <li>
                          <i class="icofont-tick-mark"></i>More accurate and
                          easier accrual reporting and forecasting capability
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="repair-about-area ptb-100 leguard-full-width-div mt-100">
            <div>
              <h3 style={{ textAlign: "center", color: "white" }}>
                LeGuard offers numerous advantages for legal service providers!
              </h3>

              <div className="row">
                <div className="col-lg-3">
                  <div className="repair-about-content">
                    <div className="section-title">
                      <p>
                        LeGuard helps legal service providers build and
                        strengthen their relationships with their clients by
                        keeping them constantly apprised of work being done on
                        their behalf and by eliminating stressful invoice
                        discussions.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <img src={circles} style={{ textAlign: "center" }}></img>
                </div>
                <div className="col-lg-3">
                  <div className="repair-about-content">
                    <div className="section-title">
                      <p>
                        When competing for work, LeGuard offers legal service
                        providers a huge differentiator that benefits clients as
                        well as themselves.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Lawfirms;
