import React from "react";
import { Link } from "react-router-dom";
import WOW from "wowjs";
import aboutImg from "../../assets/img/devices.png";
class About extends React.Component {
  componentDidMount() {
    new WOW.WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 20,
      mobile: true,
      live: true,
    }).init();
  }

  render() {
    return (
      <section id="about" className="repair-about-area ptb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="repair-about-content">
                <div className="section-title">
                  <span>About Us</span>
                  <h3>
                    A unique transparency technology that gives inhouse and
                    legal service providers LIVE visibility into activities and
                    spend – as they occur. After the fact invoices and manual
                    accrual updates are not the way to manage matters, your
                    department or your law firm – LeGuard is the answer.
                  </h3>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <img src={aboutImg}></img>
            </div>
          </div>
          <div
            className="row"
            style={{ marginTop: "50px", marginBottom: "50px" }}
          >
            <div className="col-lg-6">
              <div className="repair-about-content">
                <div className="section-title">
                  <p style={{ color: "black", fontSize: "large" }}>
                    <b>
                      LeGuard provides General Counsels and their legal
                      departments:{" "}
                    </b>
                  </p>
                </div>
                <ul>
                  <li>
                    <i class="icofont-dollar-true"></i> Reliable and up to the
                    minute accrual/activity information on legal service
                    provider work
                  </li>
                  <li>
                    <i className="icofont-handshake-deal"></i>Instant visibility
                    into inhouse work
                  </li>
                  <li>
                    <i className="icofont-attachment"></i>Improved transparency,
                    communication, and relationships with both inhouse and legal
                    service providers
                  </li>
                </ul>
                <Link
                  to="/about"
                  className="read-more-btn"
                  style={{ color: "darkred", fontSize: "2em" }}
                >
                  See how LeGuard can help you
                </Link>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="repair-about-content">
                <div className="section-title">
                  <p style={{ color: "black", fontSize: "large" }}>
                    <b>LeGuard enables legal service providers to: </b>
                  </p>
                </div>
                <ul>
                  <li>
                    <i class="icofont-connection"></i>Provide a communication
                    tool that removes financial surprise from them and their
                    clients
                  </li>
                  <li>
                    <i className="icofont-address-book"></i>See and manage their
                    teams much more closely for better realization rates
                  </li>
                  <li>
                    <i className="icofont-businessman"></i>Win new business and
                    make current clients happier by offering LeGuard’s
                    transparency
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
