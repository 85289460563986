import React, { Component } from "react";

import NavBarMultiPage from "../common/NavBarMultiPage"; //"../components/common/NavBarMultiPage";
import Footer from "../versionSeven/Footer";
import img2 from "../../assets/img/monitor.png";
import clock from "../../assets/img/clock.png";
import gcImage from "../../assets/img/GeneralCouncelPic.png";

class General extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <React.Fragment>
        <NavBarMultiPage pageName="contact" />
        <div className="page-title">
          <div className="pattern-2" />
          <div className="bg-top" />
          <div className="bg-bottom" />
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="page-title-content">
                  <h3>General Counsels - Legal Departments</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="general" className="about-area">
          <div className="section-title">
            <h3 style={{ maxWidth: "800px" }}>
              {/* Combining <b>accuracy</b> with <b>simplicity, efficiency and transparency</b>, LeGuard is transforming how
                            clients and their outside counsel communicate and manage their work and personnel. */}
              LeGuard gives General Counsels, their legal operations leadership
              and their inhouse counsel complete visibility into the work and
              corresponding costs of their matters, transforming how legal
              departments and their legal service providers communicate and
              manage their work and personnel.
            </h3>
          </div>
          <div
            className="repair-about-area ptb-100 leguard-full-width-div"
            style={{ paddingTop: "50px" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h3
                    style={{
                      textAlign: "center",
                      color: "white",
                      marginBottom: "50px",
                    }}
                  >
                    LeGuard for General Counsels with their Legal Service
                    Providers
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="repair-about-content">
                    <div className="section-title">
                      <h3>Problems</h3>
                      <ul>
                        <li>
                          <i class="icofont-close-circled"></i>Limited
                          visibility into legal activity/spend = Issues arise
                          before seen
                        </li>
                        <li>
                          <i class="icofont-close-circled"></i>Surprise invoices
                          and stressful negotiations
                        </li>
                        <li>
                          <i class="icofont-close-circled"></i>Tough to manage
                          work and budgets
                        </li>
                        <li>
                          <i class="icofont-close-circled"></i>Constant
                          gathering of data for reporting
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4" style={{ textAlign: "center" }}>
                  <span className="helper"></span>
                  <img
                    src={clock}
                    style={{ width: "50%", verticalAlign: "middle" }}
                  ></img>
                </div>
                <div className="col-lg-4">
                  <div className="repair-about-content">
                    <div className="section-title">
                      <h3>Solved!</h3>
                      <ul>
                        <li>
                          <i class="icofont-check-circled"></i>LIVE visibility
                          into spend and work-in-progress
                        </li>
                        <li>
                          <i class="icofont-check-circled"></i>No surprises
                        </li>
                        <li>
                          <i class="icofont-check-circled"></i>Transparency to
                          manage work and budgets
                        </li>
                        <li>
                          <i class="icofont-check-circled"></i>Instant financial
                          updates as work occurs
                        </li>
                        {/* <li>
                          <i class="icofont-check-circled"></i>Visibility means
                          better management and reduced costs (10 to 22%)
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
       
          <div className="container">
            <div className="row mt-100">
              <div className="col-lg-6 col-md-6">
                <div className="img">
                  <img src={img2} alt="img" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <h3 style={{ color: "#941212" }}>LeGuard for Inhouse</h3>
                <br />
                <p>
                  LeGuard’s internal feature provides the same transparency to
                  General Counsels of their inhouse teams, with live data and
                  insights of their work so it may be better understood, more
                  easily and timely managed, and optimized.
                </p>
                <br />
                <div className="repair-about-content">
                  <div className="section-title">
                    <h3 style={{ color: "#941212" }}>
                      <u>Inhouse Teams</u>
                    </h3>
                    <ul>
                      <li>
                        <i class="icofont-tick-mark"></i>Understand how much and
                        on what your team spends their time
                      </li>
                      <li>
                        <i class="icofont-tick-mark"></i>Determine the total
                        cost of a matter (inhouse and outside counsel time)
                      </li>
                      <li>
                        <i class="icofont-tick-mark"></i>Data to justify current
                        and additional headcount
                      </li>
                      <li>
                        <i class="icofont-tick-mark"></i>Better manage your
                        inhouse team
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <p>
                  The LeGuard dashboard provides 100% accurate accruals at all
                  times! With LIVE visibility into your legal service providers’
                  and/or inhouse counsels’ time/activities, corporate legal
                  departments have up-to-the-minute data upon which to base
                  strategic and budgetary decisions. Additional benefits include:
                </p> */}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row mt-100">
            <div className="col-lg-12" style={{ textAlign: "center" }}>
                  <span className="helper"></span>
                  <img
                    src={gcImage}
                  ></img>
                </div>
            </div>
          </div>
          <div
            className="repair-about-area"
          ></div>
          <div className="container">
            <div className="row mt-100">
              <div className="col-lg-6 col-md-6">
                <div className="about-text mb-0">
                  <h3 style={{ color: "#941212" }}>
                    LeGuard helps <b>reduce legal costs for everyone</b>
                  </h3>
                  <p>
                    LIVE visibility into activities and spend has the added
                    benefit of reducing costs for you and your legal service
                    providers by:
                  </p>
                  <br />

                  <ul>
                    <li>
                      <i class="icofont-dollar-true"></i>Minimizing manpower and
                      costs related to time-consuming accrual, WIP processes,
                      invoice reviews and negotiations
                    </li>
                    <li>
                      <i className="icofont-attachment"></i>Improving accrual
                      data for more efficient forecasting
                    </li>
                    <li>
                      <i class="icofont-chart-histogram-alt"></i>Enabling
                      activities to be redirected BEFORE additional time
                      accrues
                    </li>
                    <li>
                      <i className="icofont-handshake-deal"></i>Allowing
                      budgetary decisions to be made based on up-to-the-moment
                      data
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="img">
                  <img src={img2} alt="img" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default General;
