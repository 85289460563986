
const apiUrl = process.env.REACT_APP_MAIN_API; //'http://leguard2-demo-main-api.azurewebsites.net/LeGuard2.Main.API'

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        return data;
    });
}

function getPostRequestOptions(data) {
    return {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
}

function submitMessage(email, message, name, subject) {
    let postRequestOptions = getPostRequestOptions({ email, name, message, subject });
    return fetch(`${apiUrl}/api/Main/Contact`, postRequestOptions).then(handleResponse).then(data => {
        return data;
    });
}


const api = {
    submitMessage
}

export default api;