import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import NavBarMultiPage from "../components/common/NavBarMultiPage";
import Footer from "../components/versionSeven/Footer";
import charlie from "../assets/img/charlie.jpg";
import frank from "../assets/img/frank.jpg";
class Team extends React.Component {
    render() {
        //Team loop start
        const teamData = this.props.teamsData.map((team, index) => (
            <div style={{ marginBottom: '40px' }}>
                <div className="row" key={index} >
                    <div className="col-md-3">
                        <div className="single-team">
                            <div className="pic">
                                <img src={team.image} alt="team" />
                                <ul className="social-links">
                                    {team.linkedinLink && (
                                        <li>
                                            <a href={team.linkedinLink} target="blank">
                                                <i className="icofont-linkedin" />

                                            </a>
                                        </li>
                                    )}

                                    {team.twitterLink && (
                                        <li>
                                            <a href={team.twitterLink}>
                                                <i className="icofont-twitter" />
                                            </a>
                                        </li>
                                    )}

                                    {team.behanceLink && (
                                        <li>
                                            <a href={team.behanceLink}>
                                                <i className="icofont-behance" />
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                            <div className="team-content">
                                <h3 className="title">{team.name}</h3>
                                <span className="post">{team.designation}</span>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-9">
                        {team.paragraphs && team.paragraphs.length > 0 && team.paragraphs.map(x => {
                            return <React.Fragment>
                                <p>{x.text}</p><br />
                            </React.Fragment>
                        })}
                    </div>
                </div>
                <div className="separator"></div>
            </div>
        ));
        //Team loop END
        return (
            <React.Fragment>
                <NavBarMultiPage pageName="team" />
                <div className="page-title">
                    <div className="pattern-2" />
                    <div className="bg-top" />
                    <div className="bg-bottom" />
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <div className="page-title-content">
                                    <h3>{this.props.sectionName}</h3>
                                    {/* <ul>
                                        <li>
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li>
                                            <i className="icofont-thin-right" />
                                        </li>
                                        <li>Team</li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section id="team" className="team-area ptb-100 pb-0">
                    <div className="container">
                        {/* <div className="row"> */}
                        {teamData}
                        {/* </div> */}
                    </div>
                </section>
                <Footer pageName="team" />
            </React.Fragment>
        );
    }
}

//Props Types
Team.propTypes = {
    sectionName: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    servicesData: PropTypes.array
};

//Default Props
Team.defaultProps = {
    sectionName: "Team",
    sectionTitle: "Our Team Members",
    sectionDescription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",

    teamsData: [
        {

            name: "Charlie C.H. Lee",
            designation: "Esq., Founder",
            paragraphs: [
                { text: "Charlie C.H. Lee is a founding partner of Moore & Lee, LLP. He practices in all areas of litigation, focusing in construction, healthcare, general and professional liability, gaming, and corporate and commercial law. Mr. Lee has vast and unique experience in representing large national corporations, healthcare and long term care institutions, privately and publicly owned senior care providers, hospitals, insurers, sureties, Real Estate Investment Trusts (REITs), investment and other private equity groups, gaming and hotel companies, developers, owners, engineers, and large scale general contractors." },
                { text: "Mr. Lee has negotiated over 2,500 settlements in business disputes, general and professional liability claims, general and complex litigation matters, and class action suits. Throughout his career of more than thirty years, he has successfully managed, arbitrated, tried and appealed a variety of litigation matters around the country." },
                { text: "In addition to his litigation practice, Mr. Lee manages and coordinates legal matters on behalf of clients with a nationwide business presence." },
                { text: "Finally, Mr. Lee has the privilege to serve as an arbitrator on the Construction Panel and Sports Panel for the American Arbitration Association." }
            ],
            image: require("../assets/img/charlie.jpg")
        },
        {

            linkedinLink: "https://www.linkedin.com/in/frank-marvin-7807956/",
            name: "Frank R. Marvin",
            designation: "Esq., President",
            paragraphs: [
                { text: "Frank Marvin started his career as an attorney at Jones, Day, Reavis & Pogue, Washington D.C. and Los Angeles offices, and then Tenenbaum & Associates, P.C. where he served as acting in-house counsel for Trammell Crow Northeast, Inc." },
                { text: "Prior to LeGuard, Mr. Marvin was Executive Vice President of iCertainty, a SaaS company that provided health and safety technology to the food service industry, Executive Vice President at The Buller Group, a staffing and subcontracting company that provided highly cleared and highly technical personnel to major government contractors, and Chief Operating Officer of M3COM of Virginia, a government contract and commercial services company that provided telecommunications and consulting services to the U.S. Government and large commercial clients, with a specialty in international markets." },
                { text: "Mr. Marvin served as Senior Vice President, Business Development – Marketing; Vice President, Business Development – Interactive Marketing; and Vice President/COO, Business Affairs at America Online where his teams provided business strategy for and negotiation of all advertising, marketing, brand, acquisition, and member services partner relationships and deals for AOL. Prior to joining AOL, Mr. Marvin was a managing member of GFALLS, L.L.C., a venture capital and investment consortium whose services included taking three (3) U.S. technology companies to the Japanese market." },
                { text: "Mr. Marvin was also the President of SITEL Technology Services, a division of Sitel Worldwide (NYSE: SWW) spawned from a company that he co-founded, whose 2300 employees provided telephone technical support, sales support, software testing, product repair and fulfillment services for major hardware and software technology manufacturers." },
                { text: "Mr. Marvin graduated from James Madison University with a Bachelor of Business Administration with majors in Finance and Accounting and a minor in Economics, Magna Cum Laude. He earned his JD at the University of Virginia, School of Law, where he was Editor of the University of Virginia Tax Review." }
            ],
            image: require("../assets/img/frank.jpg")
        },
        {

            linkedinLink: "https://www.linkedin.com/in/greg-potteiger-3113431/",
            name: "Greg Potteiger",
            designation: "Chief Technology Officer",
            paragraphs: [
                { text: "Greg Potteiger has over twenty years of experience in designing, developing and managing software solutions in a variety of industries.  Mr. Potteiger has led development and operations teams and delivered solutions for companies such as Kaiser Permanente, UBS, AES and Freddie Mac." },
                { text: "Prior to his work in technology, Mr. Potteiger was a Submarine Officer in the US Navy, performing tours in the Pacific as well as Washington DC." },
                { text: "Mr. Potteiger graduated with distinction from the U.S. Naval Academy with a degree in Systems Engineering and also holds an MBA and Masters in Information Technology from the University of Maryland. " }
            ],
            image: require("../assets/img/greg.png")
        }
        // {
        //     fbLink: "#",
        //     twitterLink: "",
        //     behanceLink: "#",
        //     name: "David Warner",
        //     designation: "Web Developer",
        //     image: require("../assets/img/team-3.jpg")
        // },
        // {
        //     fbLink: "#",
        //     twitterLink: "#",
        //     behanceLink: "#",
        //     name: "Steven Smith",
        //     designation: "Web Developer",
        //     image: require("../assets/img/team-4.jpg")
        // },
        // {
        //     fbLink: "#",
        //     twitterLink: "#",
        //     behanceLink: "#",
        //     name: "David Walker",
        //     designation: "Web Developer",
        //     image: require("../assets/img/team-5.jpg")
        // },
        // {
        //     fbLink: "#",
        //     twitterLink: "#",
        //     behanceLink: "#",
        //     name: "David Walker",
        //     designation: "Web Developer",
        //     image: require("../assets/img/team-5.jpg")
        // }
    ]
};

export default Team;