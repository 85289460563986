import React from 'react';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import OwlCarousel from 'react-owl-carousel3';
import VisibilitySensor from "react-visibility-sensor";

const options = {
    loop: false,
    autoplay: true,
    nav: false,
    items: 1,
    mouseDrag: true,
    autoplayHoverPause: true,
    responsiveClass: true,
    dots: true,
    navText: [
        "<i class='icofont-double-left'></i>",
        "<i class='icofont-double-right'></i>"
    ]
}

class HomeSlides extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {
                name: '',
                email: '',
                phone: ''
            },
            isLoading: false
        };

    }

    openModal = () => {
        this.setState({ isOpen: true })
    };

    render() {
        return (
            <React.Fragment>
                <OwlCarousel
                    className="repair-home-slides owl-carousel owl-theme"
                    {...options}
                >
                    <div className="repair-main-banner item-bgleguard">
                        <div className="container" style={{paddingTop:'100px'}}>
                            <div className="row align-items-center">
                                <VisibilitySensor>
                                    {({ isVisible }) => (
                                        <React.Fragment>
                                            <div className="col-lg-7">
                                                <div className="repair-banner-content">
                                                    <h2
                                                        className={
                                                            isVisible ? "animated fadeInUp" : ''
                                                        }
                                                    >
                                                       
                                                    </h2>
                                                    <p
                                                        className={
                                                            isVisible ? "animated zoomIn" : ''
                                                        }
                                                    >

                                                    </p>
                                                    {/* <Link
                                                        to="#"
                                                        className={`
                                                            btn btn-primary ${isVisible ? "animated fadeInDown" : ''}
                                                        `}
                                                    >
                                                        Sign up today
                                                    </Link>{' '} */}
                                                    <Link
                                                        to="/contact"
                                                        className={`
                                                            btn btn-primary ${isVisible ? "animated fadeInDown" : ''}
                                                        `}
                                                    >
                                                        request a demo
                                                    </Link>
                                                </div>
                                            </div>
{/* 
                                            <div className="col-lg-5">
                                                <div className="banner-video text-center">
                                                    <Link
                                                        to="#"
                                                        className={`
                                                            popup-youtube ${isVisible ? "animated fadeInUp" : ''}
                                                        `}
                                                        onClick={e => { e.preventDefault(); this.openModal() }}
                                                    >
                                                        <i className="icofont-ui-play"></i>
                                                    </Link>
                                                </div>
                                            </div> */}
                                        </React.Fragment>
                                    )}
                                </VisibilitySensor>
                            </div>
                        </div>
                    </div>
                    {/* 
                    <div className="repair-main-banner item-bg2">
                        <div className="container">
                            <div className="row align-items-center">
                                <VisibilitySensor>
                                    {({ isVisible }) => (
                                        <React.Fragment>
                                            <div className="col-lg-7">
                                                <div className="repair-banner-content">
                                                    <h2
                                                        className={
                                                            isVisible ? "animated fadeInUp" : ''
                                                        }
                                                    >
                                                        Computer Repairs Any Brand, Any Problem
                                                    </h2>
                                                    <p
                                                        className={
                                                            isVisible ? "animated zoomIn" : ''
                                                        }
                                                    >
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.
                                                    </p>
                                                    <Link 
                                                        to="#" 
                                                        className={`
                                                            btn btn-primary ${isVisible ? "animated fadeInDown" : ''}
                                                        `}
                                                    >
                                                        Get a Quote
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-lg-5">
                                                <div className="banner-video text-center">
                                                    <Link 
                                                        to="#" 
                                                        className={`
                                                            popup-youtube ${isVisible ? "animated fadeInUp" : ''}
                                                        `}
                                                        onClick={e => {e.preventDefault(); this.openModal()}}
                                                    >
                                                        <i className="icofont-ui-play"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </VisibilitySensor>
                            </div>
                        </div>
                    </div>

                    <div className="repair-main-banner item-bg3">
                        <div className="container">
                            <div className="row align-items-center">
                                <VisibilitySensor>
                                    {({ isVisible }) => (
                                        <React.Fragment>
                                            <div className="col-lg-7">
                                                <div className="repair-banner-content">
                                                    <h2
                                                        className={
                                                            isVisible ? "animated fadeInUp" : ''
                                                        }
                                                    >
                                                        Multi-Brand Onsite PC Support
                                                    </h2>
                                                    <p
                                                        className={
                                                            isVisible ? "animated zoomIn" : ''
                                                        }
                                                    >
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.
                                                    </p>
                                                    <Link 
                                                        to="#" 
                                                        className={`
                                                            btn btn-primary ${isVisible ? "animated fadeInDown" : ''}
                                                        `}
                                                    >
                                                        Get a Quote
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-lg-5">
                                                <div className="banner-video text-center">
                                                    <Link 
                                                        to="#" 
                                                        className={`
                                                            popup-youtube ${isVisible ? "animated fadeInUp" : ''}
                                                        `}
                                                        onClick={e => {e.preventDefault(); this.openModal()}}
                                                    >
                                                        <i className="icofont-ui-play"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </VisibilitySensor>
                            </div>
                        </div>
                    </div> */}
                </OwlCarousel>

                <ModalVideo
                    channel='youtube'
                    isOpen={this.state.isOpen}
                    videoId='UPLi1YWfhf4'
                    onClose={() => this.setState({ isOpen: false })}
                />
            </React.Fragment>
        );
    }
}

export default HomeSlides;