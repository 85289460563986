import React from 'react';
//Components List
import NavBarMultiPage from "../components/common/NavBarMultiPage";
import Footer from "../components/versionSeven/Footer";
import HomeSlides from '../components/versionSeven/HomeSlides';
import RepairBoxes from '../components/versionSeven/RepairBoxes';
import About from '../components/versionSeven/About';
import Services from '../components/versionSeven/Services';
import Team from '../components/versionSeven/Team';
import Gallery from '../components/versionSeven/Gallery';
import Feedback from '../components/versionSeven/Feedback';
import Pricing from '../components/versionSeven/Pricing';
import Partner from '../components/versionSeven/Partner';
import Blog from '../components/versionSeven/Blog';
import VisibilitySensor from "react-visibility-sensor";


class HomeSeven extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <React.Fragment>
                {/* NavBarMultiPage: src/components/common/NavBarMultiPage */}
                <NavBarMultiPage pageName="home" />
                <HomeSlides />
                <RepairBoxes />
                <About />
                <Services />
                <div style={{height: '50px'}}></div>
                {/* <section id="about3" className="repair-about-area ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-12" style={{ textAlign: 'center' }}>

                                <h3>For more insights on managing legal spend, <br /> read the <b><u>Legal Spend Insider</u></b></h3>
                            </div>
                        </div>
                        <div className="row mt-100" >
                            <div className='col-12' style={{ textAlign: 'center' }}>
                                <VisibilitySensor>
                                    {({ isVisible }) => (
                                        <React.Fragment>

                                            <a
                                                href="https://drive.google.com/file/d/18Cb-3EOUr_6kATJ0HSxNZkYhbiZRJrwm/view"
                                                target='blank'
                                                className={`
                                                            btn btn-primary ${isVisible ? "animated fadeInDown" : ''}
                                                        `}
                                            >
                                                LEARN MORE
                                                    </a>
                                        </React.Fragment>
                                    )}
                                </VisibilitySensor>
                            </div>
                        </div>
                    </div>
                </section> */}

                {/*                 
                <Team />
                <Gallery />
                <Feedback />
                <Pricing />
                <Partner />
                <Blog /> */}

                <Footer />
            </React.Fragment>
        );
    }
}

export default HomeSeven;